import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as MinusIcon } from '@/assets/svg/counter/minus.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg/counter/plus.svg';
import { colors } from '@/constants/theme';

export interface PrimaryServiceSelectProps {
  label: string;
  options: Array<{ value: number | null; label: string }>;
  onChange: (value: number | null) => void;
  value: number | null;
  disabled?: boolean;
}

function PrimaryServiceSelect({
  onChange,
  options,
  disabled,
  value,
  label,
}: PrimaryServiceSelectProps) {
  const [optionIndex, setOptionIndex] = useState(0);

  useEffect(() => {
    setOptionIndex(options.findIndex((item) => item.value === value));
  }, [value, options]);

  const onChangeOption = (delta: number) => {
    const newOptionIndex = optionIndex + delta;
    if (newOptionIndex < 0 || newOptionIndex >= options.length) return;

    const newValue = options[newOptionIndex].value;
    onChange(newValue);
  };

  return (
    <Component>
      <Title>{label}</Title>
      <Inner>
        <DecrementButton
          type="button"
          onClick={() => onChangeOption(-1)}
          disabled={disabled || optionIndex === 0}
        >
          <MinusIcon />
        </DecrementButton>
        <LabelText>{options[optionIndex].label}</LabelText>
        <IncrementButton
          type="button"
          onClick={() => onChangeOption(1)}
          disabled={disabled || optionIndex === options.length - 1}
        >
          <PlusIcon />
        </IncrementButton>
      </Inner>
    </Component>
  );
}

export default PrimaryServiceSelect;

const Component = styled.div``;

const Title = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  font-style: normal;
`;

const Inner = styled.div`
  display: flex;
`;
const LabelText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  background-color: ${colors.white};
  flex: 1 1 auto;
`;

const CounterButton = styled.button`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid ${colors.border};
  background-color: ${colors.white};
  overflow: hidden;
  cursor: pointer;
  color: ${colors.darkText};

  &:hover {
    color: ${colors.green};
  }

  &:disabled {
    cursor: default;
    color: ${colors.blackText};
    opacity: 0.75;
  }
`;

const DecrementButton = styled(CounterButton)`
  width: 62px;
  height: 60px;
  background-color: #efefef;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 0;
`;

const IncrementButton = styled(CounterButton)`
  width: 62px;
  height: 60px;
  background-color: #efefef;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 0;
`;
