import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { media } from '@/utils/mixin';
import OrderFormMobileSummaryMain from '@/modules/Order/containers/OrderFormSummary/OrderFormMobileSummaryMain';
import { useTypedSelector } from '@/store/store';
import {
  selectPromoFormOpened,
  selectPromoValue,
} from '@/modules/Order/store/promo';
import { selectStep } from '@/modules/Order/store/common';
import { selectIsKeysDeliveryEnabled } from '@/modules/Order/store/secondary-services';

let fixPosition = 0;
let timer: any = null;
const OrderFormMobileSummary: React.FC = () => {
  const [opened, setOpened] = useState<boolean>(false);

  const wrapRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const promoFormOpened = useTypedSelector(selectPromoFormOpened);
  const promoFormValue = useTypedSelector(selectPromoValue);
  const orderStep = useTypedSelector(selectStep);
  const isKeysDeliveryEnabled = useTypedSelector(selectIsKeysDeliveryEnabled);

  useEffect(() => {
    window.addEventListener('scroll', showToolbar);
    showToolbar();
    timer = setInterval(() => {
      showToolbar();
    }, 50);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
      window.removeEventListener('scroll', showToolbar);
      showToolbar();
    };
  }, []);

  useEffect(() => {
    document.body.querySelectorAll('input').forEach((input) => {
      input.addEventListener('blur', showToolbar);
    });
    return () => {
      document.body.querySelectorAll('input').forEach((input) => {
        input.removeEventListener('blur', showToolbar);
      });
    };
  }, [orderStep, isKeysDeliveryEnabled]);

  useEffect(() => {
    showToolbar();
  }, [opened, promoFormOpened, promoFormValue]);

  const setMargin = function () {
    if (!wrapRef.current || !innerRef.current) return;

    // if toolbar wrap is hidden
    const newPosition = wrapRef.current.getBoundingClientRect().top;
    if (newPosition < -1) {
      innerRef.current.classList.add('down');
      // add a margin to show the toolbar
      fixPosition = Math.abs(newPosition); // this is new position we need to fix the toolbar in the display

      // if at the bottom of the page take a couple of pixels off due to gap
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight
      ) {
        fixPosition -= 2;
      }

      // set the margin to the new fixed position
      innerRef.current.style.marginTop = fixPosition + 'px';
    }
  };

  const debounceMargin = _.debounce(setMargin, 150);

  const showToolbar = () => {
    if (!innerRef.current) return;
    if (fixPosition > 0) {
      innerRef.current.classList.remove('down');
      fixPosition = 0;
      innerRef.current.style.marginTop = fixPosition + 'px';
    }
    debounceMargin();
  };

  const onBackgroundClick = () => {
    setOpened(false);
  };

  return (
    <>
      <Background opened={opened} onClick={onBackgroundClick} />
      <Wrapper ref={wrapRef}>
        <Component ref={innerRef}>
          <OrderFormMobileSummaryMain opened={opened} setOpened={setOpened} />
        </Component>
      </Wrapper>
    </>
  );
};

const Background = styled.div<{ opened: boolean }>`
  ${(props) =>
    props.opened
      ? css`
          position: fixed;
          inset: 0;
          background: rgba(0, 0, 0, 0.3);
          z-index: 1000000;
        `
      : null}
`;

const Wrapper = styled.div`
  display: none;

  ${media.mobile(css`
    display: block;
  `)}

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  width: 100%;
  z-index: 1000001;
  background: #fff;
`;

const Component = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-bottom: 1px solid #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  min-height: 56px;

  &.down {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
`;

export default OrderFormMobileSummary;
