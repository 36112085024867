import React from 'react';
import styled from 'styled-components';

import SelectMobile from '@/components/Select/components/SelectMobile';
import SelectDesktop from '@/components/Select/components/SelectDesktop';
import { SelectProps } from '@/components/Select/Select.types';

const Select: React.FC<SelectProps> = (props) => {
  const isMobile = typeof window.screen.orientation === 'undefined';

  const { label } = props;

  return (
    <Component>
      {label ? <Label>{label}</Label> : null}
      {isMobile ? <SelectMobile {...props} /> : <SelectDesktop {...props} />}
    </Component>
  );
};

const Component = styled.div`
  position: relative;
`;

const Label = styled.label`
  font-size: 13px;
  color: #3d3d3d;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  white-space: nowrap;
`;

export default Select;
