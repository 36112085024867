import React from 'react';
import styled, { css } from 'styled-components';

import OrderFormSidebar from '@/modules/Order/containers/OrderFormSidebar';
import { useTypedSelector } from '@/store/store';
import {
  selectOrderEditData,
  selectOrderId,
} from '@/modules/Order/store/common';
import OrderFormTitle from '@/modules/Order/components/OrderFormTitle';
import OrderFormComment from '@/modules/Order/containers/OrderFormComment';
import OrderFormAddress from '@/modules/Order/containers/OrderFormAddress/OrderFormAddress';
import OrderEditSubmit from '@/modules/Order/modules/OrderEdit/containers/OrderEditSubmit';
import OrderFormServicesAndDate from '@/modules/Order/containers/OrderFormServicesAndDate';
import OrderFormSecondaryServices from '@/modules/Order/modules/OrderEdit/containers/OrderEditSecondaryServices';
import { media } from '@/utils/mixin';
import OrderFormMobileSummary from '@/modules/Order/containers/OrderFormSummary/OrderFormMobileSummary';
import OrderFormFirstStepMobilePromo from '@/modules/Order/modules/OrderCreate/containers/OrderFormFirstStep/components/OrderFormFirstStepMobilePromo';

const OrderEditMain = () => {
  const orderId = useTypedSelector(selectOrderId);
  const model = useTypedSelector(selectOrderEditData);

  return (
    <Wrapper>
      <Main>
        <OrderFormTitle title={`Редактирование заказа № ${orderId}`} />
        <OrderFormServicesAndDate />
        <OrderFormFirstStepMobilePromo />
        <OrderFormAddress />
        {model?.isWindowsOrder ? null : <OrderFormSecondaryServices />}
        <OrderFormComment />
        <OrderEditSubmit />
      </Main>
      <OrderFormSidebar extended={true} />
      <OrderFormMobileSummary />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 66fr 34fr;
  gap: 40px;

  ${media.tabletSmallOnly(css`
    gap: 20px;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const Main = styled.div`
  ${media.tabletSmallOnly(css`
    max-width: calc(100vw - 370px);
  `)}
`;

export default OrderEditMain;
