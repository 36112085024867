import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

const ContentContainer = styled.div<{ isUserAuthorized?: boolean }>`
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  ${media.tabletSmall(css`
    max-width: 100%;
    padding: 0 15px;
  `)}
  ${media.mobileMedium(css`
    padding: 0 10px;
  `)};

  ${(props) =>
    props.isUserAuthorized
      ? css`
          ${media.mobileMedium(css`
            padding: 0 18px;
          `)};
        `
      : null}
`;

export default ContentContainer;
