import React from 'react';
import styled from 'styled-components';

import TextInput from '@/components/TextInput';

type Props = {
  label: string;
  placeholder: string;
  value: string | null;
  onChange: (value: string) => void;
};
const OrderFormInputField: React.FC<Props> = ({
  label,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <Component>
      <TextInput
        label={label}
        placeholder={placeholder}
        value={value || ''}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
      />
    </Component>
  );
};

const Component = styled.div`
  border-top: 1px dashed rgb(218, 218, 218);
  padding: 25px 0;
`;

export default OrderFormInputField;
