import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';
import { getOrderServices } from '@/services/requests/requests';
import { OrderServiceType } from '@/typings/model';

type SecondaryServiceValue = {
  id: number;
  value: boolean | number | null;
};

type CommonState = {
  secondaryServices: Array<OrderServiceType>;
  secondaryServicesLoaded: boolean;
  secondaryServicesSelected: Array<SecondaryServiceValue>;

  deliveryKeysAddress: string;

  secondaryServicesSubmitting: boolean;
};

const initialState: CommonState = {
  secondaryServices: [],
  secondaryServicesLoaded: false,
  secondaryServicesSelected: [],

  deliveryKeysAddress: '',
  secondaryServicesSubmitting: false,
};

export const loadSecondaryServices = createAsyncThunk<Array<OrderServiceType>>(
  'secondary-services/loadSecondaryServices',
  async () => {
    try {
      const response = await getOrderServices();
      return response.data;
    } catch (error) {
      return [];
    }
  }
);

const secondaryServicesSlice = createSlice({
  name: 'secondary-services',
  initialState,
  reducers: {
    reset: () => initialState,
    setDeliveryKeysAddress(state, action: PayloadAction<string>) {
      state.deliveryKeysAddress = action.payload;
    },
    setSecondaryServicesSubmitting(state, action: PayloadAction<boolean>) {
      state.secondaryServicesSubmitting = action.payload;
    },
    setSecondaryServiceValue(
      state,
      action: PayloadAction<SecondaryServiceValue>
    ) {
      if (!action.payload.value) {
        state.secondaryServicesSelected =
          state.secondaryServicesSelected.filter(
            (item) => item.id !== action.payload.id
          );
      } else {
        const found = state.secondaryServicesSelected.find(
          (item) => item.id === action.payload.id
        );
        if (found) {
          state.secondaryServicesSelected = state.secondaryServicesSelected.map(
            (item) => {
              if (item.id !== action.payload.id) {
                return item;
              }
              return {
                ...item,
                value: action.payload.value,
              };
            }
          );
        } else {
          state.secondaryServicesSelected = [
            ...state.secondaryServicesSelected,
            action.payload,
          ];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSecondaryServices.pending, (state, action) => {
        state.secondaryServices = [];
        state.secondaryServicesLoaded = false;
      })
      .addCase(loadSecondaryServices.fulfilled, (state, action) => {
        state.secondaryServices = action.payload;
        state.secondaryServicesLoaded = true;
      });
  },
});

export function selectOrderSecondaryServicesLoaded(state: AppState): boolean {
  return state.orderForm.secondaryServices.secondaryServicesLoaded;
}

export function selectOrderSecondaryServices(
  state: AppState
): Array<OrderServiceType> {
  return state.orderForm.secondaryServices.secondaryServices;
}

export function selectOrderSecondaryServicesSelected(
  state: AppState
): Array<SecondaryServiceValue> {
  return state.orderForm.secondaryServices.secondaryServicesSelected;
}

export function selectDeliveryKeysAddress(state: AppState): string {
  return state.orderForm.secondaryServices.deliveryKeysAddress;
}

export function selectSecondaryServiceSubmitting(state: AppState): boolean {
  return state.orderForm.secondaryServices.secondaryServicesSubmitting;
}

export function selectIsKeysDeliveryEnabled(state: AppState): boolean {
  let result = false;

  state.orderForm.secondaryServices.secondaryServicesSelected.forEach(
    (item) => {
      const service = state.orderForm.secondaryServices.secondaryServices.find(
        (_item) => _item.id === item.id
      );
      if (service?.mode === 'DELIVERY_KEYS') {
        result = true;
        return;
      }
    }
  );

  return result;
}

const actions = secondaryServicesSlice.actions;

export const setSecondaryServiceValue = actions.setSecondaryServiceValue;
export const setDeliveryKeysAddress = actions.setDeliveryKeysAddress;
export const resetSecondaryServices = actions.reset;
export const setSecondaryServicesSubmitting =
  actions.setSecondaryServicesSubmitting;

export default secondaryServicesSlice.reducer;
