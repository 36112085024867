import React from 'react';
import styled, { css } from 'styled-components';

import OrderFormPromoForm from '@/modules/Order/containers/OrderFormPromo/OrderFormPromoForm';
import { useTypedSelector } from '@/store/store';
import {
  selectPromoFormOpened,
  selectPromoValue,
} from '@/modules/Order/store/promo';
import OrderFormPromoSelected from '@/modules/Order/containers/OrderFormPromo/OrderFormPromoSelected';
import { media } from '@/utils/mixin';

import OrderFormSidebarBadge from './components/OrderFormSidebarBadge';
import OrderFormSidebarSummary from './components/OrderFormSidebarSummary';
import OrderFormSidebarFaq from './components/OrderFormSidebarFaq';

type Props = {
  extended?: boolean;
};
const OrderFormSidebar: React.FC<Props> = ({ extended }) => {
  const promoFormOpened = useTypedSelector(selectPromoFormOpened);
  const promoValue = useTypedSelector(selectPromoValue);

  return (
    <Component>
      <div>
        <OrderFormSidebarSummary />

        <NoMobilePromo>
          {promoFormOpened ? <OrderFormPromoForm visible={true} /> : null}
          {!promoFormOpened && promoValue ? <OrderFormPromoSelected /> : null}
        </NoMobilePromo>

        {extended ? (
          <>
            <OrderFormSidebarBadge />
            <OrderFormSidebarFaq />
          </>
        ) : null}
      </div>
    </Component>
  );
};

const NoMobilePromo = styled.div`
  ${media.mobile(css`
    display: none;
  `)}
`;

const Component = styled.div`
  ${media.tabletSmallOnly(css`
    width: 310px;
    min-width: 310px;
    max-width: 310px;
  `)}
  ${media.mobile(css`
    margin-top: 30px;
  `)}
  > div {
    position: sticky;
    top: 60px;
    padding-bottom: 60px;

    ${media.mobile(css`
      position: static;
      top: 0;
      padding-bottom: 0;
    `)}
  }
`;

export default OrderFormSidebar;
