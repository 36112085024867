import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';
import { City, getCities } from '@/services/requests/misc';

type CommonState = {
  data: Array<City>;
  activeCity: City | null;
};

const initialState: CommonState = {
  data: [],
  activeCity: null,
};

export const loadCities = createAsyncThunk<Array<City>>(
  'cities/load',
  getCities
);

const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setActiveCity(state, action: PayloadAction<City>) {
      state.activeCity = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadCities.pending, (state, action) => {
        //state.data = [];
      })
      .addCase(loadCities.fulfilled, (state, action) => {
        state.data = [...action.payload];
      });
  },
});

export function selectCities(state: AppState): Array<City> {
  return state.cities.data;
}

export function selectActiveCity(state: AppState): City | null {
  return state.cities.activeCity;
}

export const citiesActions = citiesSlice.actions;

export default citiesSlice.reducer;
