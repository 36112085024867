import React from 'react';
import styled, { css } from 'styled-components';

import {
  selectAddressCityName,
  selectAddressEntrance,
  selectAddressFlat,
  selectAddressFloor,
  selectAddressFrame,
  selectAddressHouse,
  selectAddressIntercomCode,
  selectAddressSelectedId,
  selectAddressStreet,
} from '@/modules/Order/store/address';
import { useTypedSelector } from '@/store/store';
import { dateToHumanString, getAddressHumanString } from '@/utils/common';
import {
  selectSelectedDate,
  selectSelectedTime,
} from '@/modules/Order/store/datetime';
import { media } from '@/utils/mixin';
import { selectOrderUserProfileAddresses } from '@/modules/Order/store/common';

import { ReactComponent as TickSvg } from '../svg/tick.svg';

const OrderFormSecondStepSuccessBanner = () => {
  const city = useTypedSelector(selectAddressCityName);
  const street = useTypedSelector(selectAddressStreet);
  const frame = useTypedSelector(selectAddressFrame);
  const house = useTypedSelector(selectAddressHouse);
  const floor = useTypedSelector(selectAddressFloor);
  const flat = useTypedSelector(selectAddressFlat);
  const entrance = useTypedSelector(selectAddressEntrance);
  const intercomCode = useTypedSelector(selectAddressIntercomCode);

  const date = useTypedSelector(selectSelectedDate);
  const time = useTypedSelector(selectSelectedTime);

  const addresses = useTypedSelector(selectOrderUserProfileAddresses);
  const selectedAddressId = useTypedSelector(selectAddressSelectedId);

  let addressText = selectedAddressId
    ? addresses.find((item) => item.id === selectedAddressId)?.raw
    : getAddressHumanString({
        city,
        street,
        frame,
        house,
        floor,
        flat,
        entrance,
        intercomCode,
      });

  return (
    <Component>
      <Circle>
        <TickSvg />
      </Circle>
      <Right>
        <Title>Вы успешно оформили заказ</Title>
        <Text>
          <p>
            Клинер прибудет{' '}
            <b>
              {date ? dateToHumanString(date) : ''} в {time}
            </b>{' '}
            по адресу:
            <br />
            <b>{addressText}</b>
          </p>
          <p>
            Для редактирования заказа используйте{' '}
            <a href="/personal" target="_blank">
              личный кабинет
            </a>
          </p>
        </Text>
      </Right>
    </Component>
  );
};

const Component = styled.div`
  border-radius: 5px;
  background-color: rgb(225, 246, 225);
  padding: 20px;
  position: relative;
  margin-bottom: 25px;
  display: flex;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Circle = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background-color: rgb(18, 207, 124);
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile(css`
    margin: 0 auto 20px;
  `)}
`;

const Right = styled.div`
  margin-left: 25px;

  ${media.mobile(css`
    margin: 0;
  `)}
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  display: block;
`;

const Text = styled.div`
  margin-top: 10px;
  color: rgb(134, 143, 155);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  p {
    display: block;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  b {
    font-weight: bold;
    color: #444;
  }

  a {
    color: rgb(18, 207, 124);
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }
  }
`;

export default OrderFormSecondStepSuccessBanner;
