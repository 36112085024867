import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

const OrderFormSidebarBlock = styled.div`
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(218, 218, 218);
  position: relative;
  margin-bottom: 20px;
  padding: 25px 30px;
  overflow: hidden;

  ${media.tabletSmall(css`
    padding: 20px 15px;
    margin-bottom: 15px;
  `)}
`;

export default OrderFormSidebarBlock;
