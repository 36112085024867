import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '@tager/web-core';

import { AppState } from '@/store/store';
import { PromoCodeType } from '@/enums/promo-code-type';
import { PromoCodeDiscountType } from '@/enums/promo-code-discount-type';

type PromoCodeValue = Nullable<{
  code: string;
  discount: number;
  type: PromoCodeType;
  discountType: PromoCodeDiscountType;
}>;

type PromoState = {
  formOpened: boolean;
  value: PromoCodeValue;
  submitLoading: boolean;
};

const initialState: PromoState = {
  formOpened: false,
  value: null,
  submitLoading: false,
};

const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    reset: () => initialState,
    openForm(state) {
      state.formOpened = true;
    },
    closeForm(state) {
      state.formOpened = false;
    },
    toggleForm(state) {
      state.formOpened = !state.formOpened;
    },
    setSubmitLoading(state, action: PayloadAction<boolean>) {
      state.submitLoading = action.payload;
    },
    setPromoValue(state, action: PayloadAction<PromoCodeValue>) {
      state.value = action.payload;
      if (action.payload) {
        state.formOpened = false;
      }
    },
  },
});

export const promoActions = promoSlice.actions;

export const selectPromoFormOpened = (state: AppState) =>
  state.orderForm.promo.formOpened;

export const selectPromoValue = (state: AppState) =>
  state.orderForm.promo.value;

export const selectPromoSubmitLoading = (state: AppState) =>
  state.orderForm.promo.submitLoading;

export default promoSlice.reducer;
