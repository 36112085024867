import React from 'react';
import ReactSelect from 'react-select';

import { SelectProps } from '@/components/Select/Select.types';
import { selectStyles } from '@/components/Select/components/SelectDesktop.styles';

const Select: React.FC<SelectProps> = (props) => {
  const { options, placeholder, onChange } = props;

  const value =
    typeof props.value === 'undefined'
      ? undefined
      : options.find((item) => item.value === props.value) || null;

  return (
    <ReactSelect
      options={options}
      value={value}
      placeholder={placeholder}
      onChange={(value) => {
        // @ts-ignore
        onChange && onChange(value?.value || null);
      }}
      styles={selectStyles}
      isSearchable={false}
      openMenuOnClick={true}
      openMenuOnFocus={true}
    />
  );
};

export default Select;
