import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  MapEntry,
  Nullable,
  ResourceType,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  getLastOrder,
  getOrderByAlias,
  getOrderList,
  OrderShort,
} from '@/services/requests/requests';
import {
  BusyDateType,
  CoefficientType,
  OrderServiceType,
  OrderType,
  UserType,
} from '@/typings/model';

const lastOrderLoader = createResourceLoader<Nullable<OrderType>>(null);
const editableOrderLoader = createResourceLoader<Nullable<OrderType>>(null);
const orderListLoader = createResourceLoader<Array<OrderType>>([]);

type OrderState = {
  currentOrder: Nullable<OrderShort>;
  lastOrder: ResourceType<Nullable<OrderType>>;
  editableOrder: ResourceType<Nullable<OrderType>>;
  orderList: ResourceType<Array<OrderType>>;
  orderServiceList: Array<OrderServiceType>;
  busyDateList: Array<BusyDateType>;
  coefficientList: Array<CoefficientType>;
  coefficientListForSubscribeOrderMap: Record<
    string | number,
    Array<CoefficientType>
  >;
  userListMap: Record<string, Array<UserType>>;
};

const initialState: OrderState = {
  currentOrder: null,
  lastOrder: lastOrderLoader.getInitialResource(),
  editableOrder: editableOrderLoader.getInitialResource(),
  orderList: orderListLoader.getInitialResource(),
  orderServiceList: [],
  busyDateList: [],
  coefficientList: [],
  coefficientListForSubscribeOrderMap: {},
  userListMap: {},
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    currentOrderRequestFulfilled(state, action: PayloadAction<OrderShort>) {
      state.currentOrder = action.payload;
    },
    lastOrderPending(state) {
      state.lastOrder = lastOrderLoader.pending();
    },
    lastOrderFulfilled(state, action: PayloadAction<Nullable<OrderType>>) {
      state.lastOrder = lastOrderLoader.fulfill(action.payload);
    },
    lastOrderRejected(state) {
      state.lastOrder = lastOrderLoader.reject();
    },
    editableOrderPending(state) {
      state.editableOrder = editableOrderLoader.pending();
    },
    editableOrderFulfilled(state, action: PayloadAction<Nullable<OrderType>>) {
      state.editableOrder = editableOrderLoader.fulfill(action.payload);
    },
    editableOrderRejected(state) {
      state.editableOrder = editableOrderLoader.reject();
    },
    orderListPending(state) {
      state.orderList = orderListLoader.pending();
    },
    orderListFulfilled(state, action: PayloadAction<Array<OrderType>>) {
      state.orderList = orderListLoader.fulfill(action.payload);
    },
    orderListRejected(state) {
      state.orderList = orderListLoader.reject();
    },
    orderServiceListFulfilled(
      state,
      action: PayloadAction<Array<OrderServiceType>>
    ) {
      state.orderServiceList = action.payload;
    },
    busyDateListFulfilled(state, action: PayloadAction<Array<BusyDateType>>) {
      state.busyDateList = action.payload;
    },
    coefficientListFulfilled(
      state,
      action: PayloadAction<Array<CoefficientType>>
    ) {
      state.coefficientList = action.payload;
    },
    coefficientListForSubscribeOrderFulfilled(
      state,
      action: PayloadAction<MapEntry<string | number, Array<CoefficientType>>>
    ) {
      state.coefficientListForSubscribeOrderMap[action.payload.key] =
        action.payload.value;
    },
    userListFormSearchFulfilled(
      state,
      action: PayloadAction<MapEntry<string, Array<UserType>>>
    ) {
      state.userListMap[action.payload.key] = action.payload.value;
    },
  },
});

const { actions, reducer } = orderSlice;

export const {
  currentOrderRequestFulfilled,
  lastOrderPending,
  lastOrderFulfilled,
  orderListPending,
  orderListFulfilled,
} = actions;

export default reducer;

export function getOrderByAliasThunk(alias: string): AppThunk {
  return async (dispatch) => {
    try {
      const response = await getOrderByAlias(alias);
      dispatch(currentOrderRequestFulfilled(response.data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getLastOrderThunk(): AppThunk {
  return async (dispatch) => {
    try {
      dispatch(lastOrderPending());

      const response = await getLastOrder();
      dispatch(lastOrderFulfilled(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
}

export function getOrderListThunk(): AppThunk {
  return async (dispatch) => {
    try {
      dispatch(orderListPending());
      const response = await getOrderList();
      dispatch(orderListFulfilled(response.data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateOrderListAfterDeleteThunk(id: number): AppThunk {
  return async (dispatch, useState) => {
    try {
      const orderList = orderListResource(useState()).data;

      const newOrderList = orderList.filter((order) => order.id !== id);

      dispatch(orderListFulfilled(newOrderList));
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateOrderListAfterConfirmThunk(
  id: number,
  order: OrderType
): AppThunk {
  return async (dispatch, useState) => {
    try {
      const orderList = orderListResource(useState()).data;

      const orderIndex = orderList.indexOf(
        orderList.find((order) => order.id === id) as OrderType
      );

      const newOrderList = orderList.filter((order) => order.id !== id);

      newOrderList.splice(orderIndex, 0, order);
      dispatch(orderListFulfilled(newOrderList));
    } catch (error) {
      console.error(error);
    }
  };
}

export function currentOrderSelector(state: AppState): Nullable<OrderShort> {
  return state.order.currentOrder;
}

export function lastOrderResource(
  state: AppState
): ResourceType<Nullable<OrderType>> {
  return state.order.lastOrder;
}
export function orderListResource(
  state: AppState
): ResourceType<Array<OrderType>> {
  return state.order.orderList;
}

export function getUserListFromSearch(
  state: AppState,
  query: Nullable<string>
): Array<UserType> {
  return state.order.userListMap[query ?? '-'];
}
