import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  error: string;
};
const OrderError = ({ error }: Props) => {
  return <Error>{error}</Error>;
};

const Error = styled.div`
  border: 1px solid ${colors.red};
  padding: 20px 100px;
  text-align: center;
  font-size: 20px;
  color: ${colors.red};
  font-weight: bold;
`;

export default OrderError;
