import React, { useEffect, useRef, useState } from 'react';
import { useSelect } from 'downshift';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { ReactComponent as WatchIcon } from '@/assets/svg/watch.svg';
import { scrollToElement, scrollToElementAsync } from '@/utils/scroll';
import { isInViewport } from '@/modules/Order/utils';
import { media } from '@/utils/mixin';

export interface TimeSelectProps {
  id?: string;
  value: string | null;
  options: Array<{ value: string; disabled?: boolean }>;
  onChange?: (value: Nullable<{ value: string; disabled?: boolean }>) => void;
  label: string;
  disabled?: boolean;
  forceOpen?: boolean;
}

function TimeSelect({
  id,
  onChange,
  disabled,
  label,
  options,
  value,
  forceOpen,
}: TimeSelectProps) {
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const [opened, setOpened] = useState<boolean>(false);

  const onOpen = () => {
    if (window.innerWidth < 767 && ref.current) {
      window.removeEventListener('scroll', scrollHandler);
      scrollToElementAsync(ref.current, 60).then(() => {
        window.addEventListener('scroll', scrollHandler);
      });
    }
  };

  const scrollHandler = () => {
    if (menuRef.current && !isInViewport(menuRef.current)) {
      closeMenu();
    }
  };

  useEffect(() => {
    if (opened) {
      window.addEventListener('scroll', scrollHandler);
    } else {
      window.removeEventListener('scroll', scrollHandler);
    }
  }, [opened]);

  useEffect(() => {
    if (forceOpen) {
      openMenu();
    }
  }, [forceOpen]);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    closeMenu,
    openMenu,
  } = useSelect({
    selectedItem: options.find((item) => item.value === value),
    items: options,
    onSelectedItemChange: (changes) => {
      onChange && onChange(changes.selectedItem ?? null);
      setOpened(false);
    },
    onIsOpenChange: () => {
      if (!isOpen) {
        setOpened(true);
        onOpen();
        return;
      }
    },
  });

  return (
    <Container id={id} ref={ref}>
      <ValueButton
        {...(!disabled ? getToggleButtonProps() : null)}
        isOpen={opened}
        isDisabled={disabled}
        type={'button'}
      >
        <WatchIcon />
        <SelectedItemLabel>
          {selectedItem && value ? selectedItem?.value : label}
        </SelectedItemLabel>
      </ValueButton>
      <Menu isOpen={opened} {...getMenuProps()} ref={menuRef}>
        {opened &&
          options.map((item, index) => (
            <MenuItem
              isActive={
                selectedItem ? selectedItem.value === item.value : false
              }
              isDisabled={item.disabled || disabled}
              key={item.value}
              {...getItemProps({
                item,
                index,
                disabled: item.disabled || disabled,
              })}
            >
              <Item>{item.value}</Item>
            </MenuItem>
          ))}
      </Menu>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;
const ValueButton = styled.button<{ isOpen: boolean; isDisabled: boolean }>`
  width: 100%;
  font-size: 16px;
  background-color: ${(props) =>
    props.isDisabled ? '#99999940' : `${colors.white}`};
  border: 1px solid #dedede;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: ${(props) => (props.isDisabled ? 'default' : `pointer`)};
  padding: 15px 50px;
  transition: all 200ms ease;
  text-align: left;

  svg {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
  }
`;
const SelectedItemLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.blackText};
`;

const Menu = styled.ul<{ isOpen: boolean }>`
  width: 100%;
  position: absolute;
  background-color: ${colors.white};
  z-index: 1;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.25),
    0 0 1px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  max-height: 200px;
  overflow-y: scroll;
  transition: 0.3s;
  padding: 5px 0;
  display: ${(props) => (props.isOpen ? 'block' : `none`)};
`;
const MenuItem = styled.li<{ isActive: boolean; isDisabled: boolean }>`
  padding: 10px 20px;
  font-size: 16px;
  overflow: hidden;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive ? '#f2f9fc' : `${colors.white}`};
  color: ${(props) => (props.isActive ? '#333' : `rgba(51, 51, 51, 0.8)`)};

  ${media.laptopUp(css`
    &:hover {
      background-color: #f2f9fc;
      color: #333;
    }
  `)}
`;

const Item = styled.span``;

export default TimeSelect;
