import React from 'react';
import styled, { css } from 'styled-components';
import { format, Locale } from 'date-fns';

import { ReactComponent as CalendarArrowIcon } from '@/assets/svg/green-arrow.svg';

type Props = {
  locale?: Locale;
  headerProps: {
    date: Date;
    changeYear(year: number): void;
    changeMonth(month: number): void;
    decreaseMonth(): void;
    increaseMonth(): void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
    decreaseYear(): void;
    increaseYear(): void;
    prevYearButtonDisabled: boolean;
    nextYearButtonDisabled: boolean;
  };
};

function CustomHeader({ headerProps, locale }: Props) {
  return (
    <Container>
      <DecreaseMonth
        onClick={headerProps.decreaseMonth}
        disabled={headerProps.prevMonthButtonDisabled}
      />
      {format(headerProps.date, 'LLLL yyyy', { locale: locale })}
      <IncreaseMonth
        onClick={headerProps.increaseMonth}
        disabled={headerProps.nextMonthButtonDisabled}
      />
    </Container>
  );
}

export default CustomHeader;

const Container = styled.div`
  font-size: 0.944rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
  text-transform: capitalize;
  svg {
    position: absolute;
    top: 4px;
    cursor: pointer;
  }
`;

const DecreaseMonth = styled(CalendarArrowIcon)<{ disabled: boolean }>`
  left: 0;
  transform: rotate(90deg);
  ${(props) =>
    props.disabled
      ? css`
          display: none;
        `
      : null}
`;

const IncreaseMonth = styled(CalendarArrowIcon)<{ disabled: boolean }>`
  right: 0;
  transform: rotate(-90deg);
  ${(props) =>
    props.disabled
      ? css`
          display: none;
        `
      : null}
`;
