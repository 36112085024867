import React from 'react';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectOrderComment,
  selectOrderSubmitLoading,
  setOrderComment,
} from '@/modules/Order/store/first-step';
import { TextArea } from '@/components/TextInput';
import OrderFormSection from '@/modules/Order/components/OrderFormSection';

const OrderFormComment = () => {
  const dispatch = useTypedDispatch();
  const submitLoading = useTypedSelector(selectOrderSubmitLoading);

  const orderComment = useTypedSelector(selectOrderComment);
  const onCommentChange = (value: string) => dispatch(setOrderComment(value));

  return (
    <OrderFormSection blocked={submitLoading}>
      <TextArea
        label="Комментарий"
        placeholder="Ваш комментарий к заказу"
        value={orderComment || ''}
        onChange={(e) => {
          onCommentChange(e.target.value);
        }}
      />
    </OrderFormSection>
  );
};

export default OrderFormComment;
