import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { getSeoPages } from '@/services/requests/requests';

type SeoPagesListState = {
  seoPagesList: Nullable<Array<string>>;
};

const seoPagesListSlice = createSlice({
  name: 'seoPagesList',
  initialState: {
    seoPagesList: null,
  } as SeoPagesListState,
  reducers: {
    seoPagesListRequestFulfilled(state, action: PayloadAction<Array<string>>) {
      state.seoPagesList = action.payload;
    },
  },
});

const { actions } = seoPagesListSlice;

export function getSeoPagesThunk(): AppThunk {
  return async (dispatch) => {
    try {
      const response = await getSeoPages();
      dispatch(actions.seoPagesListRequestFulfilled(response.data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function seoPagesListSelector(state: AppState): Nullable<Array<string>> {
  return state.seoPagesList.seoPagesList;
}

export default seoPagesListSlice.reducer;
