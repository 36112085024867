import React, { useState } from 'react';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectDeliveryKeysAddress,
  selectOrderSecondaryServicesSelected,
  selectIsKeysDeliveryEnabled,
  selectOrderSecondaryServicesLoaded,
  selectSecondaryServiceSubmitting,
  setSecondaryServicesSubmitting,
} from '@/modules/Order/store/secondary-services';
import OrderFormSubmit from '@/modules/Order/components/OrderFormSubmit';
import {
  selectOrderId,
  setErrorMessage,
  setStep,
} from '@/modules/Order/store/common';
import {
  setSecondaryServices,
  SetSecondaryServicesRequest,
} from '@/services/requests/orders';

const OrderFormSecondStepSubmit = () => {
  const dispatch = useTypedDispatch();

  const submitDisabled = !useTypedSelector(selectOrderSecondaryServicesLoaded);
  const orderId = useTypedSelector(selectOrderId);
  const selectedServices = useTypedSelector(
    selectOrderSecondaryServicesSelected
  );
  const keysDeliveryAddress = useTypedSelector(selectDeliveryKeysAddress);
  const isKeysDeliveryEnabled = useTypedSelector(selectIsKeysDeliveryEnabled);

  const loading = useTypedSelector(selectSecondaryServiceSubmitting);

  const onlySkip =
    selectedServices.length === 0 && keysDeliveryAddress?.trim().length === 0;

  const validate = (): string | null => {
    if (
      isKeysDeliveryEnabled &&
      String(keysDeliveryAddress).trim().length === 0
    ) {
      return 'Введите адрес доставки ключей';
    }

    return null;
  };

  const getRequest = (): SetSecondaryServicesRequest | null => {
    return {
      services:
        selectedServices
          .filter((item) => item.value !== null)
          .map((item) => {
            return {
              id: item.id,
              value: Number(item.value),
            };
          }) || [],
      keysDeliveryAddress,
    };
  };

  const onSkip = () => {
    dispatch(setStep(3));
  };

  const onSubmit = async () => {
    if (!orderId) return;

    const error = validate();
    if (error) {
      dispatch(setErrorMessage(error));
      return;
    }

    const request = getRequest();
    if (!request) {
      dispatch(setErrorMessage('Ошибка формирования запроса'));
      return;
    }

    dispatch(setSecondaryServicesSubmitting(true));
    try {
      await setSecondaryServices(orderId, request);
      dispatch(setStep(3));
    } catch (e) {
      dispatch(setErrorMessage('Ошибка добавления дополнительных услуг'));
    } finally {
      dispatch(setSecondaryServicesSubmitting(false));
    }
  };

  if (onlySkip) {
    return (
      <OrderFormSubmit
        isContainer={true}
        submitLabel={`Пропустить`}
        submitDisabled={submitDisabled}
        onSubmit={onSkip}
        mobileNoFixed={true}
      />
    );
  } else {
    return (
      <OrderFormSubmit
        isContainer={true}
        submitLabel={`Добавить услуги к моей уборке`}
        submitLoading={loading}
        submitDisabled={submitDisabled}
        onSkip={loading ? undefined : onSkip}
        onSubmit={onSubmit}
        skipHidden={loading}
      />
    );
  }
};

export default OrderFormSecondStepSubmit;
