import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

type Props = {
  blocked?: boolean;
  title?: string;
  noLine?: boolean;
};

const OrderFormSection: React.FC<Props> = ({
  blocked,
  noLine,
  title,
  children,
  ...props
}) => {
  return (
    <Section blocked={!!blocked} noLine={noLine} {...props}>
      {title ? <Title>{title}</Title> : null}
      {children}
    </Section>
  );
};

const Section = styled.div<{ blocked: boolean; noLine?: boolean }>`
  ${(props) =>
    !props.noLine &&
    css`
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px dashed
        ${props.blocked ? 'rgba(218, 218, 218, 0.6)' : 'rgb(218, 218, 218)'};

      ${media.tabletSmall(css`
        padding-bottom: 20px;
        margin-bottom: 20px;
      `)}
    `}

  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease;
  }

  ${(props) =>
    props.blocked &&
    css`
      &:before {
        visibility: visible;
        opacity: 1;
      }
    `}
`;

const Title = styled.span`
  display: block;
  font-size: 26px;
  line-height: 100%;
  margin-bottom: 30px;
  font-weight: 500;

  ${media.tabletSmall(css`
    font-size: 20px;
    margin-bottom: 20px;
  `)}
`;

export default OrderFormSection;
