import React from 'react';

import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import OrderFormSecondaryServices from '@/modules/Order/containers/OrderFormSecondaryServices';
import { useTypedSelector } from '@/store/store';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';

const OrderEditSecondaryServices = () => {
  const loading = useTypedSelector(selectOrderSubmitLoading);

  return (
    <OrderFormSection title="Дополнительные услуги" blocked={loading}>
      <OrderFormSecondaryServices loadServices={false} />
    </OrderFormSection>
  );
};

export default OrderEditSecondaryServices;
