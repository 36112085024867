import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';

type CommonState = {
  submitLoading: boolean;
  orderComment: string;

  promoFormFocused: boolean;
};

const initialState: CommonState = {
  submitLoading: false,
  orderComment: '',
  promoFormFocused: false,
};

const firstStepSlice = createSlice({
  name: 'first-step',
  initialState,
  reducers: {
    reset: () => initialState,
    setSubmitLoading(state, action: PayloadAction<boolean>) {
      state.submitLoading = action.payload;
    },
    setOrderComment(state, action: PayloadAction<string>) {
      state.orderComment = action.payload;
    },
    setPromoFormFocused(state, action: PayloadAction<boolean>) {
      state.promoFormFocused = action.payload;
    },
  },
});

export function selectOrderSubmitDisabled(state: AppState): boolean {
  return state.orderForm.promo.submitLoading;
}

export function selectOrderSubmitLoading(state: AppState): boolean {
  return state.orderForm.firstStep.submitLoading;
}

export function selectOrderComment(state: AppState): string {
  return state.orderForm.firstStep.orderComment;
}
export function selectPromoFormFocused(state: AppState): boolean {
  return state.orderForm.firstStep.promoFormFocused;
}

const actions = firstStepSlice.actions;

export const setOrderComment = actions.setOrderComment;
export const setSubmitLoading = actions.setSubmitLoading;
export const setPromoFormFocused = actions.setPromoFormFocused;

export const resetStepOne = actions.reset;

export default firstStepSlice.reducer;
