import React from 'react';

import { SvgComponent } from '@tager/web-components';
import { Nullable } from '@tager/web-core';

import { ReactComponent as UserIcon } from '@/assets/svg/user-icon.svg';
import { ReactComponent as LogoutIcon } from '@/assets/svg/logout-icon.svg';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow.svg';

import * as S from './Button.style';
import { ColorVariant, ModeButtonVariant } from './Button.style';

type ButtonIconType = 'logout' | 'user' | 'arrow';

type CommonProps = {
  colorVariant?: ColorVariant;
  modeVariant?: ModeButtonVariant;
  as?: React.ElementType;
  loader?: boolean;
  icon?: ButtonIconType;
  iconPosition?: 'left' | 'right';
};

function getIconComponent(
  icon: ButtonIconType | undefined
): Nullable<SvgComponent> {
  switch (icon) {
    case 'user':
      return UserIcon;
    case 'logout':
      return LogoutIcon;
    case 'arrow':
      return ArrowIcon;
    default:
      return null;
  }
}

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CommonProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      icon,
      iconPosition = 'left',
      children,
      ...rest
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const Icon = getIconComponent(icon);

    return (
      <S.StyledButton ref={ref} type={type} {...rest}>
        {Icon && iconPosition === 'left' ? (
          <S.IconComponent as={Icon} position={iconPosition} />
        ) : null}
        {children}
        {Icon && iconPosition === 'right' ? (
          <S.IconComponent as={Icon} position={iconPosition} />
        ) : null}
      </S.StyledButton>
    );
  }
);

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & CommonProps;

export const ButtonLink = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (props, ref) => {
    return <S.StyledButton as="a" ref={ref} {...props} />;
  }
);

export default Button;
