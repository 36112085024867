import React from 'react';
import { PropsWithoutRef, RefAttributes } from 'react';

import { createLinkComponent } from '@tager/web-components';
import { TagerLinkProps } from '@tager/web-components/src/components/createLinkComponent';

import { selectActiveCity } from '@/store/reducers/cities';
import { useTypedSelector } from '@/store/store';
import { selectPageList } from '@/store/reducers/tager/pages';
import { City } from '@/services/requests/misc';

type Props = PropsWithoutRef<TagerLinkProps> &
  RefAttributes<HTMLAnchorElement> & { city?: City };

const Link: React.FC<Props> = (props) => {
  const activeCity = useTypedSelector(selectActiveCity);
  const pages = useTypedSelector(selectPageList);

  const TagerLink = createLinkComponent({
    converter: (link) => {
      if (!link) return null;

      const validUrls = pages
        .filter((item) => item.template !== null)
        .map((item) => item.path);

      if (!validUrls.includes(link.toString())) {
        return link;
      }

      if (props && props.city) {
        const to = props.to?.toString();
        return props.city.isDefault ? to : '/' + props.city.urlAlias + to;
      }

      if (!activeCity || activeCity?.isDefault) {
        return link;
      }

      return '/' + activeCity.urlAlias + link;
    },
  });

  return <TagerLink {...props} />;
};

export default Link;
