import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

type TextInputProps = {
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  numeric?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, numeric, label, value, ...rest }: TextInputProps, ref) => {
    return (
      <Component className={className}>
        {label ? <Label>{label}</Label> : null}
        <Input
          {...rest}
          ref={ref}
          value={value || ''}
          onKeyPress={(event) => {
            if (numeric && !/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          type={numeric ? 'tel' : 'text'}
          autoComplete="off"
          pattern={numeric ? '[0-9]*' : undefined}
        />
      </Component>
    );
  }
);

export default TextInput;

const Component = styled.div`
  position: relative;
`;

const Label = styled.label`
  font-size: 13px;
  color: #3d3d3d;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  white-space: nowrap;
`;
const Input = styled.input<{
  error?: string;
}>`
  width: 100%;
  height: 50px;
  border: 1px solid ${colors.border};
  line-height: 1.6;
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
  border-radius: 8px;
  color: ${colors.black};

  &::placeholder {
    color: #b3b3bb;
  }

  &:focus {
    border-color: ${colors.green};
  }

  &:disabled {
    opacity: 0.9;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.red};
    `}
`;
