import { request } from '@tager/web-core';

export type City = {
  id: number;
  name: string;
  nameWhere: string;
  urlAlias: string;
  isDefault: boolean;
};

export function getCities(): Promise<Array<City>> {
  return request.get({ path: `/misc/cities` });
}

export function geolocation(ipAddress: string): Promise<{
  id: number;
  name: string;
}> {
  return request.get({ path: `/misc/geolocation/${ipAddress}` });
}
