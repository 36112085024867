import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Option } from '@tager/web-core';

import Spinner from '@/components/Spinner';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  loadSecondaryServices,
  selectOrderSecondaryServicesSelected,
  selectOrderSecondaryServices,
  selectOrderSecondaryServicesLoaded,
  setSecondaryServiceValue,
  selectDeliveryKeysAddress,
  setDeliveryKeysAddress,
  selectIsKeysDeliveryEnabled,
} from '@/modules/Order/store/secondary-services';
import ServiceSelect from '@/components/ServiceSelect';
import { OrderServiceType } from '@/typings/model';
import { workTimeHumanString } from '@/utils/common';
import OrderFormInputField from '@/modules/Order/components/OrderFormInputField';
import { media } from '@/utils/mixin';

type Props = {
  loadServices?: boolean;
  disabled?: boolean;
};

const OrderFormSecondStepServices: React.FC<Props> = ({
  loadServices = true,
  disabled = false,
}) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (loadServices) {
      dispatch(loadSecondaryServices());
    }
  }, []);

  const loaded = useTypedSelector(selectOrderSecondaryServicesLoaded);
  const services = useTypedSelector(selectOrderSecondaryServices);
  const servicesSelected = useTypedSelector(
    selectOrderSecondaryServicesSelected
  );

  const deliveryKeysAddress = useTypedSelector(selectDeliveryKeysAddress);
  const onDeliveryKeysAddressChange = (value: string) =>
    dispatch(setDeliveryKeysAddress(value));

  const isKeysDeliveryEnabled = useTypedSelector(selectIsKeysDeliveryEnabled);

  const getValue = (id: number): number | boolean => {
    return servicesSelected.find((item) => item.id === id)?.value || false;
  };

  const onChange = (id: number, value: number | boolean) => {
    dispatch(setSecondaryServiceValue({ id, value }));
  };

  const getOptions = (service: OrderServiceType): Array<Option<number>> => {
    let result: Array<Option<number>> = [];

    if (service.mode === 'QUANTITY') {
      for (let i = 1; i <= 20; i++) {
        result.push({
          label: String(i) + ' шт.',
          value: i,
        });
      }
    }

    if (service.mode === 'TIME') {
      for (let i = 1; i <= 20; i++) {
        result.push({
          label: workTimeHumanString(i * service.step),
          value: i * service.step,
        });
      }
    }

    return result;
  };

  return (
    <Main>
      {loaded ? (
        <>
          <Grid disabled={disabled}>
            {services.map((item) => (
              <ServiceSelect
                key={item.id}
                mode={
                  item.mode === 'DELIVERY_KEYS' || item.mode === 'TRUE_FALSE'
                    ? 'boolean'
                    : 'select'
                }
                label={item.name}
                tooltip={item.tooltip}
                icon={item.icon}
                iconActive={item.iconActive}
                value={getValue(item.id)}
                onChange={(value) => !disabled && onChange(item.id, value)}
                isNew={item.isNew}
                options={getOptions(item)}
              />
            ))}
          </Grid>
          {isKeysDeliveryEnabled ? (
            <OrderFormInputField
              label="Адрес доставки ключей"
              placeholder="Адрес, по которому клинер доставит ключи после уборки"
              value={deliveryKeysAddress}
              onChange={(value) =>
                !disabled && onDeliveryKeysAddressChange(value)
              }
            />
          ) : null}
        </>
      ) : (
        <Spinner />
      )}
    </Main>
  );
};

const Main = styled.div`
  min-height: 440px;
  position: relative;
`;

const Grid = styled.div<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};
  transition: 0.3s all ease;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 30px;

  ${media.tablet(css`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `)}

  ${media.tabletSmall(css`
    grid-template-columns: 1fr 1fr 1fr;
  `)}

  ${media.mobile(css`
    grid-template-columns: 1fr 1fr;
  `)}
`;

export default OrderFormSecondStepServices;
