import React, { useEffect } from 'react';

import OrderWrapper from '@/modules/Order/containers/OrderWrapper';
import OrderEditLoader from '@/modules/Order/modules/OrderEdit/containers/OrderEditLoader';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  resetOrderCommonState,
  selectOrderEditError,
  selectOrderEditLoaded,
  selectOrderSettingsLoaded,
  selectOrderUserProfileError,
  setOrderId,
  setStep,
} from '@/modules/Order/store/common';
import OrderEditMain from '@/modules/Order/modules/OrderEdit/containers/OrderEditMain';
import {
  resetPrimaryServices,
  selectServiceOptionsLoaded,
} from '@/modules/Order/store/services';
import {
  dateTimeActions,
  selectBusyDatesLoaded,
  selectCoefficientsLoaded,
} from '@/modules/Order/store/datetime';
import {
  resetSecondaryServices,
  selectOrderSecondaryServicesLoaded,
} from '@/modules/Order/store/secondary-services';
import { promoActions } from '@/modules/Order/store/promo';
import { addressActions } from '@/modules/Order/store/address';
import OrderError from '@/modules/Order/components/OrderError';

type Props = {
  id: number;
};

const OrderEdit: React.FC<Props> = ({ id }) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(resetOrderCommonState());
    dispatch(setStep(1));
    dispatch(resetPrimaryServices());
    dispatch(resetSecondaryServices());

    dispatch(addressActions.reset());
    dispatch(dateTimeActions.reset());
    dispatch(promoActions.reset());
  }, []);

  useEffect(() => {
    dispatch(setOrderId(id));
  }, [id]);

  const orderEditError = useTypedSelector(selectOrderEditError);

  const loaded = useTypedSelector((state) => {
    return (
      selectOrderSettingsLoaded(state) &&
      selectOrderEditLoaded(state) &&
      selectServiceOptionsLoaded(state) &&
      selectBusyDatesLoaded(state) &&
      selectOrderSecondaryServicesLoaded(state) &&
      selectCoefficientsLoaded(state)
    );
  });

  const error = useTypedSelector((state) => {
    return selectOrderUserProfileError(state);
  });

  return (
    <OrderWrapper>
      {orderEditError ? (
        <h1>{orderEditError}</h1>
      ) : !loaded ? (
        <OrderEditLoader />
      ) : error ? (
        <OrderError error={error} />
      ) : (
        <OrderEditMain />
      )}
    </OrderWrapper>
  );
};

export default OrderEdit;
