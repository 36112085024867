import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

import Button from '@/components/Button';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectPromoFormFocused } from '@/modules/Order/store/first-step';

type Props = {
  isContainer?: boolean;
  submitLabel: string;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  skipHidden?: boolean;
  onSubmit?: () => void;
  hasAgreement?: boolean;
  onSkip?: () => void;
  mobileNoFixed?: boolean;
};

const OrderFormSubmit: React.FC<Props> = ({
  isContainer,
  submitLoading,
  submitLabel,
  submitDisabled,
  onSubmit,
  onSkip,
  hasAgreement,
  skipHidden,
  mobileNoFixed,
}) => {
  const promoFormFocused = useTypedSelector(selectPromoFormFocused);

  const onSkipClick: MouseEventHandler = (e) => {
    e.preventDefault();
    onSkip && onSkip();
  };

  const onSubmitClick: MouseEventHandler = (e) => {
    e.preventDefault();
    onSubmit && onSubmit();
  };

  return (
    <SubmitWrapper
      isContainer={!!isContainer}
      noFixed={promoFormFocused || mobileNoFixed}
    >
      <SubmitButton
        colorVariant="yellow"
        modeVariant="contained"
        loader={submitLoading}
        disabled={submitDisabled}
        onClick={onSubmitClick}
      >
        {submitLabel}
      </SubmitButton>
      {hasAgreement ? (
        <Agreement>
          Нажимая кнопку "Оформить заказ", вы принимаете условия{' '}
          <a target="_blank" href="/terms">
            Пользовательского соглашения
          </a>
        </Agreement>
      ) : onSkip && !skipHidden ? (
        <Button colorVariant="green" modeVariant="text" onClick={onSkipClick}>
          Пропустить
        </Button>
      ) : null}
    </SubmitWrapper>
  );
};

const SubmitWrapper = styled.div<{ isContainer: boolean; noFixed?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  > *:nth-child(2) {
    margin-left: 40px;
  }

  ${(props) =>
    props.isContainer
      ? css`
          border-top: 1px dashed rgb(218, 218, 218);
          padding-top: 25px;
        `
      : null}

  ${media.tablet(css`
    flex-direction: column;

    > * :first-child {
      width: 100%;
    }

    > *:nth-child(2) {
      margin: 10px 0 0 0;
    }
  `)}

  ${media.mobile(css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 12px 10px;
    background: #fff;
    z-index: 100;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-top: 0 none;
  `)}
  
  ${(props) =>
    props.noFixed &&
    media.mobile(css`
      position: static;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
    `)}
`;

const SubmitButton = styled(Button)`
  text-transform: uppercase;
  padding-left: 100px;
  padding-right: 100px;
  white-space: nowrap;
`;

const Agreement = styled.p`
  font-size: 12px;
  color: rgb(134, 143, 155);
  font-weight: 500;
  max-width: 384px;

  ${media.tablet(css`
    margin: 20px 0 0 0;
    max-width: 100%;
  `)}

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export default OrderFormSubmit;
