import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowSvg } from '../svg/faq-arrow.svg';

const QUESTIONS = [
  {
    question: 'Где и что вы убираете?',
    answer:
      'Мы проводим уборку во всех помещениях квартиры. Посмотрите <a href="/uslugi/uborka-kvartir" target="_blank" class="link">здесь</a>, что мы отмоем.',
  },
  {
    question: 'Как работает подписка?',
    answer:
      'Вы выбираете, как часто вам нужна уборка, и за день до заказа получаете смс для подтверждения заказа. Мы будем приезжать в то время, которое вам будет необходимо, каждую неделю или каждые две недели. И никакой предоплаты — оплата производится по завершению уборки нашему клинеру.',
  },
  {
    question: 'Как изменить или отменить заказ?',
    answer:
      'Зайдите в личный кабинет, найдите нужный заказ и измените его с помощью кнопки "редактировать". Или же позвоните нам по номеру +375(29) 133-12-13',
  },
];
const OrderFormSidebarFaq = () => {
  const [opened, setOpened] = useState<number[]>([]);

  const toggle = (ind: number) => {
    if (opened.includes(ind)) {
      setOpened(opened.filter((item) => item !== ind));
    } else {
      setOpened([...opened, ind]);
    }
  };

  return (
    <Component>
      <Title>Всё, что вам нужно знать</Title>
      {QUESTIONS.map((question, ind) => (
        <QuestionWrapper opened={opened.includes(ind)} key={ind}>
          <Question onClick={() => toggle(ind)}>
            <ArrowSvg />
            {question.question}
          </Question>
          <Answer
            dangerouslySetInnerHTML={{
              __html: question.answer,
            }}
          />
        </QuestionWrapper>
      ))}
    </Component>
  );
};

const Component = styled.div`
  margin-top: 20px;
`;

const Title = styled.span`
  display: block;
  font-size: 22px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  margin-bottom: 15px;
`;

const Question = styled.span`
  display: flex;
  align-items: center;
  color: rgb(18, 207, 124);
  cursor: pointer;

  svg {
    margin-right: 10px;
    margin-left: 2px;
    transition: 0.3s all ease;
  }
`;

const Answer = styled.p`
  display: none;
  color: rgb(134, 143, 155);
  padding-left: 24px;

  a {
    color: rgb(18, 207, 124);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const QuestionWrapper = styled.div<{ opened: boolean }>`
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  font-size: 14px;
  line-height: 24px;
  font-weight: 700;

  ${(props) =>
    props.opened &&
    css`
      ${Answer} {
        display: block;
      }

      ${Question} {
        svg {
          transform: rotate(180deg);
        }
      }
    `}
`;

export default OrderFormSidebarFaq;
