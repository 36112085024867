import React from 'react';

import OrderFormSecondStepSuccessBanner from '@/modules/Order/modules/OrderCreate/containers/OrderFormSecondStep/components/OrderFormSecondStepSuccessBanner';
import OrderFormSecondStepServices from '@/modules/Order/modules/OrderCreate/containers/OrderFormSecondStep/components/OrderFormSecondStepServices';
import OrderFormSecondStepSubmit from '@/modules/Order/modules/OrderCreate/containers/OrderFormSecondStep/components/OrderFormSecondStepSubmit';

const OrderFormSecondStep = () => {
  return (
    <>
      <OrderFormSecondStepSuccessBanner />
      <OrderFormSecondStepServices />
      <OrderFormSecondStepSubmit />
    </>
  );
};

export default OrderFormSecondStep;
