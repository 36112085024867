import React from 'react';

import IconCheckbox from '@/components/IconCheckbox';

import { ReactComponent as HouseIcon } from './svg/house.svg';

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
};

const OrderFormHouseCheckbox: React.FC<Props> = (props) => (
  <IconCheckbox
    {...props}
    icon={<HouseIcon />}
    label={'Частный дом'}
    badge="x 1.2"
  />
);

export default OrderFormHouseCheckbox;
