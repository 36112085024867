import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { scroller } from '@tager/web-core';

import { numberTo2 } from '@/utils/common';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectUserPrice } from '@/modules/Order/store';
import { colors } from '@/constants/theme';
import OrderFormDetailedSummary from '@/modules/Order/containers/OrderFormSummary/OrderFormDetailedSummary';
import {
  promoActions,
  selectPromoFormOpened,
} from '@/modules/Order/store/promo';
import ProfileDropdown from '@/components/Layout/components/ProfileDropdown';

import { ReactComponent as CloseIconSvg } from '../../svg/close-icon.svg';

type Props = {
  opened: boolean;
  setOpened: (value: boolean) => void;
};

const OrderFormMobileSummaryMain: React.FC<Props> = ({ opened, setOpened }) => {
  const dispatch = useTypedDispatch();
  const userPrice = useTypedSelector(selectUserPrice);
  const openedPromo = useTypedSelector(selectPromoFormOpened);

  const detailedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (opened) {
      scroller.lock(detailedRef.current);
      dispatch(promoActions.closeForm());
    } else {
      scroller.unlock();
    }
  }, [opened]);

  useEffect(() => {
    if (openedPromo) {
      setOpened(false);
    }
  }, [openedPromo]);

  return (
    <>
      <Preview>
        <Price>
          <PriceLabel>К оплате: </PriceLabel>
          <PriceValue>
            {Math.floor(userPrice)}{' '}
            <sub>
              {numberTo2(Math.round((userPrice - Math.floor(userPrice)) * 100))}
            </sub>
            <small>BYN</small>
          </PriceValue>
        </Price>
        <Right>
          {opened ? (
            <CloseAction onClick={() => setOpened(false)}>
              <CloseIconSvg />
            </CloseAction>
          ) : (
            <Arrow onClick={() => setOpened(true)}>Ваш заказ</Arrow>
          )}
          <ProfileDropdown colorVariant="green" />
        </Right>
      </Preview>
      <Detailed opened={opened} ref={detailedRef}>
        <OrderFormDetailedSummary isMobile={true} />
      </Detailed>
    </>
  );
};

const Right = styled.div`
  display: flex;
  gap: 10px;
`;

const Arrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.green};
  position: relative;
  padding-right: 25px;
  font-weight: 700;
  font-size: 16px;

  &:after {
    content: '';
    position: absolute;
    right: 6px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${colors.green};
  }
`;

const Preview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 12px;
  position: relative;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
`;

const PriceLabel = styled.span`
  font-size: 15px;
  color: rgb(61, 61, 61);
  font-weight: 600;
  margin-right: 10px;
`;

const PriceValue = styled.span`
  font-size: 30px;
  font-weight: 900;
  color: rgb(18, 207, 124);
  margin-right: -6px;

  small {
    font-size: 50%;
  }

  sub {
    top: -0.5em;
    left: -5px;
    font-size: 70%;
    font-weight: 700;
  }
`;

const Detailed = styled.div<{ opened: boolean }>`
  display: ${(props) => (props.opened ? 'block' : 'none')};
  border-top: 1px solid #ddd;
  padding: 20px 30px;
  margin: 1px -15px 0;

  max-height: calc(var(--vh, 1vh) * 100 - 55px);
  overflow: auto;
`;

const CloseAction = styled.button`
  color: ${colors.red};
  display: flex;
  margin-right: 5px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export default OrderFormMobileSummaryMain;
