import React from 'react';

import OrderFormPersonal from '@/modules/Order/containers/OrderFormPersonal';
import OrderFormFirstStepSubmit from '@/modules/Order/modules/OrderCreate/containers/OrderFormFirstStep/components/OrderFormFirstStepSubmit';
import OrderFormComment from '@/modules/Order/containers/OrderFormComment';
import OrderFormServicesAndDate from '@/modules/Order/containers/OrderFormServicesAndDate';
import OrderFormAddress from '@/modules/Order/containers/OrderFormAddress/OrderFormAddress';
import OrderFormFirstStepMobilePromo from '@/modules/Order/modules/OrderCreate/containers/OrderFormFirstStep/components/OrderFormFirstStepMobilePromo';
import OrderFormBalance from '@/modules/Order/containers/OrderFormBalance';

const OrderFormFirstStep = () => {
  return (
    <>
      <OrderFormServicesAndDate />
      <OrderFormFirstStepMobilePromo />
      <OrderFormAddress />
      <OrderFormBalance />
      <OrderFormPersonal />
      <OrderFormComment />
      <OrderFormFirstStepSubmit />
    </>
  );
};

export default OrderFormFirstStep;
