import React from 'react';

import OrderFormThirdStepCleaner from '@/modules/Order/modules/OrderCreate/containers/OrderFormThirdStep/components/OrderFormThirdStepCleaner';
import OrderFormThirdStepSubscription from '@/modules/Order/modules/OrderCreate/containers/OrderFormThirdStep/components/OrderFormThirdStepSubscription';
import OrderFormThirdStepSubmit from '@/modules/Order/modules/OrderCreate/containers/OrderFormThirdStep/components/OrderFormThirdStepSubmit';

const OrderFormThirdStep = () => {
  return (
    <>
      <OrderFormThirdStepCleaner />
      <OrderFormThirdStepSubscription />
      <OrderFormThirdStepSubmit />
    </>
  );
};

export default OrderFormThirdStep;
