import React from 'react';

import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import IconCheckbox from '@/components/IconCheckbox';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectUseBalance,
  selectUserBalance,
  setUseBalance,
  setUsedBalance,
} from '@/modules/Order/store/common';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';
import { selectUserPrice } from '@/modules/Order/store';

const OrderFormBalance = () => {
  const dispatch = useTypedDispatch();
  const userPrice = useTypedSelector(selectUserPrice);
  const useBalance = useTypedSelector(selectUseBalance);
  const userBalance = useTypedSelector(selectUserBalance);
  const submitLoading = useTypedSelector(selectOrderSubmitLoading);

  const onChange = () => {
    if (useBalance) {
      dispatch(setUseBalance(false));
      dispatch(setUsedBalance(0));
    } else {
      dispatch(setUseBalance(true));
      dispatch(setUsedBalance(userBalance));
    }
  };

  if (!userBalance) return null;

  return (
    <OrderFormSection blocked={submitLoading}>
      <IconCheckbox
        label="Использовать бонусные баллы для оплаты заказа"
        checked={useBalance}
        onChange={onChange}
        badge={`-${Math.min(userPrice, userBalance)} BYN`}
      />
    </OrderFormSection>
  );
};

export default OrderFormBalance;
