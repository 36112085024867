import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Nullable, Option } from '@tager/web-core';
import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { ReactComponent as QuestionIcon } from '@/assets/svg/question.svg';
import Counter from '@/components/ServiceSelect/components/Counter';

export interface ServiceSelectProps {
  mode: 'select' | 'boolean';
  label: string;
  tooltip: string;
  icon: Nullable<{ url: string }>;
  iconActive: Nullable<{ url: string }>;
  value: boolean | number;
  onChange: (value: boolean | number) => void;
  options?: Array<Option<number>>;
  isNew: Nullable<boolean>;
}

function ServiceSelect({
  mode,
  label,
  tooltip,
  iconActive,
  icon,
  value,
  options,
  onChange,
  isNew,
}: ServiceSelectProps) {
  let defaultValue = -1;
  if (value) {
    if (typeof value === 'boolean') {
      defaultValue = value ? 1 : -1;
    } else if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          defaultValue = i;
          break;
        }
      }
    }
  }

  const [count, setCount] = useState(defaultValue);

  const handleClick = () => {
    if (mode === 'boolean') {
      onChange(!value);
    } else {
      options && count === -1 && onChange(options[0].value);
      options && count === -1 && setCount(0);
    }
  };

  const handleCount = (value: number) => {
    setCount(value);

    options && value > -1 && onChange(options[value].value);
    options && value === -1 && onChange(0);
  };

  return (
    <Item>
      {isNew ? <Badge>NEW</Badge> : null}
      <InputBlock isActive={!!value} onClick={handleClick}>
        {tooltip ? (
          <TooltipBlock>
            <QuestionIcon />
            <TooltipText>{tooltip}</TooltipText>
          </TooltipBlock>
        ) : null}
        <Icon
          {...convertThumbnailToPictureImage(iconActive)}
          isHide={!value || mode === 'select'}
        />
        <Icon {...convertThumbnailToPictureImage(icon)} isHide={!!value} />
        {!!options?.length && count > -1 && mode === 'select' ? (
          <CounterBlock>
            <Counter
              count={count}
              handleCount={handleCount}
              options={options}
            />
          </CounterBlock>
        ) : null}
      </InputBlock>
      <Label>{label}</Label>
    </Item>
  );
}

export default ServiceSelect;

const TooltipText = styled.span`
  position: absolute;
  bottom: 150%;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 12px;
  background-color: ${colors.black};
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  color: ${colors.white};
  font-weight: 600;
  line-height: 1.45;
  right: -10px;
  z-index: 111;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: 0.2s, 0.3s;
  transition-delay: 0.2s, 0.2s;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    right: 14px;
    border-top: 5px solid rgba(0, 0, 0, 0.9);
  }
`;

const TooltipBlock = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 1;
  text-align: center;

  &:hover {
    ${TooltipText} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Item = styled.li`
  display: block;
  width: 100%;
  position: relative;
`;

const Badge = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -9px;
  background: #10ba70;
  color: #fff;
  font-weight: 700;
  display: block;
  font-size: 11px;
  padding: 4px 15px;
  z-index: 1;
  border-radius: 15px;
`;

const InputBlock = styled.div<{ isActive: boolean }>`
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: inset 0 0 0 1px #dadada;
  height: 85px;
  position: relative;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;

  &:before,
  &:after {
    content: '';
    box-sizing: inherit;
    position: absolute;
    border: 2px solid transparent;
    width: 0;
    height: 0;
    border-radius: 5px;
  }

  &:before {
    top: 0;
    left: 0;
  }

  &:after {
    right: 0;
    bottom: 0;
  }

  &:hover {
    border-color: transparent;

    &:before,
    &:after {
      width: 100%;
      height: 100%;
    }

    &:before {
      border-top-color: ${colors.yellowLight};
      border-right-color: ${colors.yellowLight};
      transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
    }

    &:after {
      border-bottom-color: ${colors.yellowLight}; // Make borders visible
      border-left-color: ${colors.yellowLight};
      transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
        height 0.25s ease-out 0.75s;
    }
  }

  ${(props) =>
    props.isActive
      ? css`
          border: 1px solid ${colors.green};

          &:before,
          &:after {
            content: none;
          }

          &:hover {
            border-color: ${colors.green};
          }
        `
      : null}
`;

const Icon = styled(Picture)<{ isHide: boolean }>`
  display: ${(props) => (props.isHide ? 'none' : 'inline-block')};
  margin: 0 auto;

  img {
    max-height: 35px;
    max-width: 100px;
    user-select: none;
  }
`;

const Label = styled.div`
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
`;

const CounterBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 6px;
  transform: translate(0, -50%);
`;
