import React, { useEffect } from 'react';

import {
  loadOrder,
  loadOrderSettings,
  loadOrderUserProfile,
  selectOrderEditData,
  selectOrderId,
  setMode,
  setUsedBalance,
} from '@/modules/Order/store/common';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  dateTimeActions,
  loadBusyDatesThunk,
  loadDateCoefficientsThunk,
  selectBusyDatesLoaded,
} from '@/modules/Order/store/datetime';
import { OrderFormMode } from '@/enums/order-form-mode';
import {
  loadRoomsAndBathroomsServicesThunk,
  loadWindowsAndBalconiesServicesThunk,
  setFirstServiceQuantity,
  setSecondServiceQuantity,
} from '@/modules/Order/store/services';
import { addressActions } from '@/modules/Order/store/address';
import { stringToDate } from '@/utils/common';
import { setOrderComment } from '@/modules/Order/store/first-step';
import { OrderType } from '@/services/requests/orders';
import {
  loadSecondaryServices,
  setDeliveryKeysAddress,
  setSecondaryServiceValue,
} from '@/modules/Order/store/secondary-services';
import { promoActions } from '@/modules/Order/store/promo';
import { PromoCodeType } from '@/enums/promo-code-type';
import { PromoCodeDiscountType } from '@/enums/promo-code-discount-type';
import OrderLoader from '@/modules/Order/components/OrderLoader';
import { selectCities } from '@/store/reducers/cities';

const OrderEditLoader = () => {
  const dispatch = useTypedDispatch();

  const orderId = useTypedSelector(selectOrderId);
  const model = useTypedSelector(selectOrderEditData);
  const busyDatesLoaded = useTypedSelector(selectBusyDatesLoaded);
  const cities = useTypedSelector(selectCities);

  useEffect(() => {
    if (!orderId) return;

    dispatch(loadOrder({ id: orderId }));
  }, [orderId]);

  useEffect(() => {
    if (!model) return;

    dispatch(loadOrderSettings());
    dispatch(loadSecondaryServices());
    dispatch(loadOrderUserProfile());
    dispatch(loadBusyDatesThunk());
    dispatch(loadDateCoefficientsThunk({ id: orderId ?? undefined }));

    const mode = model.isWindowsOrder
      ? OrderFormMode.Windows
      : OrderFormMode.Rooms;

    dispatch(setMode(mode));

    if (mode === OrderFormMode.Rooms) {
      dispatch(loadRoomsAndBathroomsServicesThunk());
    } else if (mode === OrderFormMode.Windows) {
      dispatch(loadWindowsAndBalconiesServicesThunk());
    }

    setServices(model);
    setAddress(model);
    setDateTime(model);
    setPromoCode(model);

    dispatch(setUsedBalance(model.usedBalance));

    dispatch(setOrderComment(model.comment ?? ''));
  }, [model]);

  useEffect(() => {
    if (busyDatesLoaded && model && model.datetime) {
      dispatch(dateTimeActions.addAvailableTimeSlot(model.datetime));
    }
  }, [busyDatesLoaded, model]);

  const setAddress = (model: OrderType) => {
    if (model.address.id) {
      dispatch(addressActions.setSelectedAddressId(model.address.id));
    } else {
      dispatch(
        addressActions.setCity(
          cities.find((item) => item.id === model.city) || cities[0]
        )
      );
      dispatch(addressActions.setStreet(String(model.address.street)));
      dispatch(addressActions.setHouse(model.address.house));
      dispatch(
        addressActions.setFrame(
          model.address.wing ? String(model.address.wing) : ''
        )
      );
      dispatch(addressActions.setFlat(model.address.apartment));
      dispatch(
        addressActions.setFloor(
          model.address.floor ? Number(model.address.floor) : null
        )
      );
      dispatch(addressActions.setEntrance(model.address.entrance));
      dispatch(
        addressActions.setIntercomCode(
          model.address.codeIntercom ? String(model.address.codeIntercom) : ''
        )
      );
      dispatch(addressActions.setType(model.type));
    }
  };

  const setDateTime = (model: OrderType) => {
    dispatch(dateTimeActions.setSelectedDate(stringToDate(model.datetime)));
    dispatch(dateTimeActions.setSelectedTime(model.datetime.substring(11, 16)));
    if (model.dayCoefficient !== 1) {
      dispatch(dateTimeActions.setCoefficient(model.dayCoefficient));
    }
  };

  const setServices = (model: OrderType) => {
    const primaryServices = model.services.filter(
      (item) => item.type === 'PRIMARY'
    );

    dispatch(setFirstServiceQuantity(primaryServices[0].count));
    dispatch(setSecondServiceQuantity(primaryServices[1]?.count || 0));

    dispatch(setDeliveryKeysAddress(model.keyDeliveryAddress ?? ''));

    const secondaryServices = model.services.filter(
      (item) => item.type === 'SECONDARY'
    );

    secondaryServices.forEach((secondaryService) => {
      const value =
        secondaryService.mode === 'TRUE_FALSE' ||
        secondaryService.mode === 'DELIVERY_KEYS'
          ? true
          : secondaryService.mode === 'QUANTITY'
          ? secondaryService.count
          : secondaryService.count * secondaryService.step;

      dispatch(
        setSecondaryServiceValue({
          id: secondaryService.id,
          value,
        })
      );
    });
  };

  const setPromoCode = (model: OrderType) => {
    if (model.promocode) {
      dispatch(
        promoActions.setPromoValue({
          code: model.promocode.code,
          discount: model.promocode.discount,
          type:
            model.promocode.type === 'PROMOCODE'
              ? PromoCodeType.Promo
              : PromoCodeType.Referral,
          discountType:
            model.promocode.discountType === 'DISCOUNT_PERCENT'
              ? PromoCodeDiscountType.Percent
              : PromoCodeDiscountType.Amount,
        })
      );
    } else if (model.referralCode) {
      dispatch(
        promoActions.setPromoValue({
          code: model.referralCode,
          discount: Number(model.referralDiscount),
          type: PromoCodeType.Referral,
          discountType: PromoCodeDiscountType.Amount,
        })
      );
    } else {
      dispatch(promoActions.reset());
    }
  };

  return <OrderLoader />;
};

export default OrderEditLoader;
