import React, { useEffect, useState } from 'react';

import Toast from '@/components/Toast';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectErrorMessage,
  setErrorMessage,
} from '@/modules/Order/store/common';

let timer: NodeJS.Timeout | null = null;

const OrderErrorMessage = () => {
  const dispatch = useTypedDispatch();
  const errorMessage = useTypedSelector(selectErrorMessage);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (errorMessage === 'WAITING') {
      return;
    }

    if (!errorMessage) {
      setError(null);
      return;
    }

    dispatch(setErrorMessage('WAITING'));

    if (error) {
      setError(null);
      setTimeout(() => {
        setError(errorMessage);
      }, 200);

      if (timer) {
        clearTimeout(timer);
      }
    } else {
      setError(errorMessage);

      timer = setTimeout(() => {
        setError(null);
      }, 3000);
    }
  }, [errorMessage]);

  return <Toast message={error} type="error" position="top" />;
};

export default OrderErrorMessage;
