import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as DatePickerIcon } from '@/assets/svg/date-picker.svg';

type CustomInputProps = React.InputHTMLAttributes<HTMLDivElement>;

function CustomInput({ value, placeholder, onClick }: CustomInputProps) {
  return (
    <Container
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        onClick ? onClick(e) : null
      }
    >
      <DatePickerIcon />
      {value === '' ? placeholder : value}
    </Container>
  );
}

export default CustomInput;

const Container = styled.div`
  width: 100%;
  height: 50px;
  border: 1px solid #dedede;
  line-height: 1.6;
  font-size: 14px;
  font-weight: 500;
  padding: 14px 15px 15px 50px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
  }
`;
