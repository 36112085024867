import React, { useEffect } from 'react';

import { scroller } from '@tager/web-core';

import Spinner from '@/components/Spinner';

const OrderLoader = () => {
  useEffect(() => {
    scroller.lock();
    return () => {
      scroller.unlock();
    };
  }, []);

  return <Spinner />;
};

export default OrderLoader;
