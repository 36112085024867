function smoothScroll(targetPosition: number): Promise<void> {
  targetPosition = Math.ceil(targetPosition);

  window.scrollTo({
    top: targetPosition,
    behavior: 'smooth',
  });

  return new Promise((resolve, reject) => {
    const failed = setTimeout(() => {
      resolve();
    }, 1000);

    const scrollHandler = () => {
      if (window.scrollY === targetPosition) {
        window.removeEventListener('scroll', scrollHandler);
        clearTimeout(failed);
        resolve();
      }
    };

    if (window.scrollY === targetPosition) {
      clearTimeout(failed);
      resolve();
    } else {
      window.addEventListener('scroll', scrollHandler);
    }
  });
}

export function scrollToElement(element: HTMLElement | string, delta = 0) {
  const htmlElement =
    typeof element === 'string' ? document.getElementById(element) : element;
  if (!htmlElement) return;

  setTimeout(() => {
    window.scrollTo({
      top:
        htmlElement.getBoundingClientRect().top - 75 + window.scrollY - delta,
      behavior: 'smooth',
    });
  }, 0);
}

export async function scrollToElementAsync(
  element: HTMLElement | string,
  delta: number = 0
) {
  const htmlElement =
    typeof element === 'string' ? document.getElementById(element) : element;
  if (!htmlElement) return;

  return smoothScroll(
    htmlElement.getBoundingClientRect().top - 75 + window.scrollY - delta
  );
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}
