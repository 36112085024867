import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '@tager/web-core';

import { SeoPage } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import { getPageByAlias } from '@/services/requests/requests';

type SeoPageState = {
  currentPage: Nullable<SeoPage>;
};

const seoPageSlice = createSlice({
  name: 'seoPage',
  initialState: {
    currentPage: null,
  } as SeoPageState,
  reducers: {
    currentPageRequestFulfilled(state, action: PayloadAction<SeoPage>) {
      state.currentPage = action.payload;
    },
  },
});

const { actions } = seoPageSlice;

export function getPageByAliasThunk(alias: string): AppThunk {
  return async (dispatch) => {
    try {
      const response = await getPageByAlias(alias);
      dispatch(actions.currentPageRequestFulfilled(response.data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function currentPageSelector(state: AppState): Nullable<SeoPage> {
  return state.seoPage.currentPage;
}

export default seoPageSlice.reducer;
