import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import React from 'react';
import { useDispatch } from 'react-redux';

import { redirect } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { selectUserProfile, signOutThunk } from '@/store/reducers/auth';
import { useTypedSelector } from '@/store/store';
import { ReactComponent as UserIcon } from '@/assets/svg/user-icon.svg';
import { ReactComponent as SpriteIcon } from '@/assets/svg/sprite.svg';
import { ReactComponent as QuestionIcon } from '@/assets/svg/question.svg';
import { ThemeColor } from '@/typings/common';
import { ColorVariant } from '@/components/Layout/components/Header/Header';
import { getColorByVariant } from '@/utils/common';
import Link from '@/components/Link';
import { useTranslation } from '@/i18n';

type Props = { colorVariant: ThemeColor };

function ProfileDropdown({ colorVariant }: Props) {
  const { t } = useTranslation();

  const router = useRouter();
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOutThunk());
    redirect({ currentUrl: router.asPath ?? '', location: '/' });
  }

  const userProfile = useTypedSelector((state) => selectUserProfile(state));

  return (
    <Container>
      <ActionButton variant={colorVariant}>
        <UserIcon />
        <MenuTitleText variant={colorVariant}>
          {userProfile?.name}
        </MenuTitleText>
        <SpriteIcon />
      </ActionButton>

      <Menu>
        <MenuList>
          <MenuItem>
            <MenuItemBonusText variant={colorVariant}>
              {t('header_bonus', { bonus: userProfile?.bonuses.toFixed(2) })}
              <TooltipBlock>
                <QuestionIcon />
                <TooltipText>{t('tooltip_bonus')}</TooltipText>
              </TooltipBlock>
            </MenuItemBonusText>
          </MenuItem>
          <MenuItem>
            <MenuItemLink as={Link} variant={colorVariant} to="/profile/orders">
              {t('header_profile_order')}
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              as={Link}
              variant={colorVariant}
              to="/profile/settings"
            >
              {t('header_profile_setting')}
            </MenuItemLink>
          </MenuItem>
          <MenuItem onClick={handleSignOut}>
            <MenuItemButton variant={colorVariant}>
              {t('header_profile_exit')}
            </MenuItemButton>
          </MenuItem>
        </MenuList>
      </Menu>
    </Container>
  );
}

const Menu = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 20px 0;
  opacity: 0;
  visibility: hidden;
  margin-top: 10px;
  z-index: 20;
  border: 1px solid ${colors.blockBorder};
  min-width: 235px;
  transition: 0.2s;

  ${media.mobile(
    css`
      width: 200px;
      right: 0;
    `
  )};
`;

const Container = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    height: 10px;
    left: 0;
    width: 100%;
    cursor: pointer;
    ${media.mobile(css`
      display: none;
    `)}
  }

  &:hover {
    ${Menu} {
      opacity: 1;
      visibility: visible;
      transition: 0.2s;
      top: 100%;
      //top: 54%;
    }
  }
`;

const ActionButton = styled.button<{ variant: ColorVariant }>`
  padding-right: 18px;
  padding-left: 25px;
  color: ${colors.green};
  ${media.mobile(css`
    padding: 0;
  `)}

  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    display: block;
    fill: ${(props) => getColorByVariant(props.variant)};
    :first-child {
      left: 0;
      bottom: 0;
      ${media.mobile(css`
        left: auto;
        right: 0;
      `)}
    }

    :last-child {
      position: absolute;
      right: 0;
      bottom: 3px;
      ${media.mobile(css`
        display: none;
      `)}
    }
  }
`;

const MenuTitleText = styled.span<{ variant: ColorVariant }>`
  border-bottom: 1px dashed ${(props) => getColorByVariant(props.variant)};
  color: ${(props) => getColorByVariant(props.variant)};
  ${media.mobile(css`
    display: none;
  `)}
`;

const MenuList = styled.ul``;
const MenuItemBonusText = styled.span<{ variant: ColorVariant }>`
  display: inline-block;
  color: ${(props) => getColorByVariant(props.variant)};
  margin-bottom: 5px;
  svg {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    position: relative;
  }
`;

const TooltipText = styled.span`
  position: absolute;
  top: 100%;
  bottom: auto;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 12px;
  background-color: ${colors.black};
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  color: ${colors.white};
  font-weight: 600;
  line-height: 1.45;
  right: -10px;
  z-index: 111;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: 0.2s, 0.3s;
  transition-delay: 0.2s, 0.2s;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: 100%;
    right: 14px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.9);
  }
`;

const TooltipBlock = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-top: 2px;
  margin-left: 5px;
  cursor: pointer;
  position: relative;
  &:hover {
    ${TooltipText} {
      opacity: 1;
      visibility: visible;
    }
  }
`;
const MenuItem = styled.li`
  padding: 0 20px;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
`;

const menuItemCss = css<{ variant: ColorVariant }>`
  display: block;
  margin: 0 -20px;
  padding: 0 20px;
  line-height: 30px;
  transition: 0s;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => getColorByVariant(props.variant)};
    color: ${colors.white};
  }
`;

const MenuItemLink = styled.a<{ variant: ColorVariant }>`
  ${menuItemCss};
`;

const MenuItemButton = styled.a`
  ${menuItemCss};
`;

export default ProfileDropdown;
