import React from 'react';
import styled, { css } from 'styled-components';

import OrderFormSidebarBlock from '@/modules/Order/containers/OrderFormSidebar/components/OrderFormSidebarBlock';
import { media } from '@/utils/mixin';
import OrderFormDetailedSummary from '@/modules/Order/containers/OrderFormSummary/OrderFormDetailedSummary';

const OrderFormSidebarSummary: React.FC = (props) => {
  return (
    <Component {...props}>
      <OrderFormDetailedSummary />
    </Component>
  );
};

const Component = styled(OrderFormSidebarBlock)`
  ${media.mobile(css`
    display: none;
  `)}
`;

export default OrderFormSidebarSummary;
