import path from 'path';

import NextI18Next, { InitConfig } from 'next-i18next';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export type Lang = 'ru' | 'de';

export const LANGUAGES = {
  RU: 'ru',
  DE: 'de',
} as const;

const NAMESPACE = [
  'common',
  'home',
  'order',
  'profile',
  'profile-order',
  'profile-settings',
  'auth',
  'password-restore',
  'error-pages',
  'office',
];

const DEFAULT_LANGUAGE: Lang =
  (process.env.NEXT_PUBLIC_LANGUAGE as Lang) ?? 'ru';
const OTHER_LANGUAGES = [DEFAULT_LANGUAGE];
// const OTHER_LANGUAGES = Object.values(LANGUAGES).filter(
//   (lang) => lang !== DEFAULT_LANGUAGE
// );

/**
 * Detector Options reference:
 * https://github.com/i18next/i18next-http-middleware#detector-options
 */
type DetectorType = 'path' | 'session' | 'querystring' | 'cookie' | 'header';

type DetectorOptions = {
  order?: Array<DetectorType>;
  lookupQuerystring?: string;
  lookupCookie?: string;
  lookupHeader?: string;
  lookupSession?: string;
  lookupPath?: string;
  lookupFromPathIndex?: string;
  caches?: boolean | Array<DetectorType>;
  cookieExpirationDate?: Date;
  cookieDomain?: string;
  cookieSecure?: boolean;
};

type I18NextInitConfig = Omit<
  InitConfig,
  'detection' | 'defaultLanguage' | 'otherLanguages'
> & {
  defaultLanguage: typeof DEFAULT_LANGUAGE;
  otherLanguages: Array<Lang>;
  detection?: DetectorOptions;
};

/**
 * Default config reference:
 * https://github.com/isaachinman/next-i18next/blob/master/src/config/default-config.ts
 */
export const i18nConfig: I18NextInitConfig = {
  defaultLanguage: DEFAULT_LANGUAGE,
  otherLanguages: OTHER_LANGUAGES,
  localeSubpaths: publicRuntimeConfig.localeSubpaths,
  localePath: path.resolve('./public/static/locales'),
  fallbackLng: process.env.NEXT_PUBLIC_LANGUAGE ?? 'ru',
  localeStructure: '{{lng}}/{{ns}}',
  fallbackNS: NAMESPACE,
  detection: {
    lookupCookie: 'lng',
    order: ['path', 'cookie', 'header'],
    caches: false,
  },
  browserLanguageDetection: false,
};

const NextI18NextInstance = new NextI18Next(i18nConfig);

export const { appWithTranslation, Link, i18n, useTranslation } =
  NextI18NextInstance;

export default NextI18NextInstance;
