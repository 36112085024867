import React from 'react';
import styled, { css } from 'styled-components';

import OrderFormPromoApply from '@/modules/Order/containers/OrderFormPromo/OrderFormPromoApply';
import OrderFormPromoForm from '@/modules/Order/containers/OrderFormPromo/OrderFormPromoForm';
import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import { useTypedSelector } from '@/store/store';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';
import {
  selectPromoFormOpened,
  selectPromoValue,
} from '@/modules/Order/store/promo';
import { selectIsOrderEdit, selectStep } from '@/modules/Order/store/common';
import { media } from '@/utils/mixin';
import OrderFormPromoSelected from '@/modules/Order/containers/OrderFormPromo/OrderFormPromoSelected';

const OrderFormFirstStepMobilePromo = () => {
  const step = useTypedSelector(selectStep);
  const submitLoading = useTypedSelector(selectOrderSubmitLoading);
  const promoFormOpened = useTypedSelector(selectPromoFormOpened);
  const promoValue = useTypedSelector(selectPromoValue);

  const promoApplyHidden = step !== 1 || promoValue;

  const render = (): React.ReactElement | null => {
    if (promoValue) {
      return <OrderFormPromoSelected />;
    }

    return (
      <>
        <OrderFormPromoForm
          visible={promoFormOpened}
          containerId={'promo-form-mobile'}
        />
        {!promoApplyHidden ? <OrderFormPromoApply isMobile={true} /> : null}
      </>
    );
  };

  return <Section blocked={submitLoading}>{render()}</Section>;
};

const Section = styled(OrderFormSection)`
  display: none;
  > div {
    margin-bottom: 0;
  }
  ${media.mobile(css`
    display: block;
  `)}
`;

export default OrderFormFirstStepMobilePromo;
