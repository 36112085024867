import styled, { css, keyframes } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export type ModeButtonVariant = 'contained' | 'outlined' | 'text';

export type ColorsButtonVariant = 'green' | 'yellow' | 'blue' | 'red';

export type ColorVariant = ColorsButtonVariant | CssSnippet;

const colorTextCssMap: Record<ColorsButtonVariant, CssSnippet> = {
  yellow: css`
    color: ${colors.yellow};
    &:hover {
      color: ${colors.yellowDark};
    }
    &:hover[disabled] {
      color: ${colors.yellow};
    }
  `,
  green: css`
    color: ${colors.green};
    &:hover {
      color: ${colors.greenHover};
    }
    &:hover[disabled] {
      color: ${colors.green};
    }
  `,
  blue: css`
    color: ${colors.blue};
    &:hover {
      color: ${colors.blueButtonHover};
    }
    &:hover[disabled] {
      color: ${colors.blue};
    }
  `,
  red: css`
    color: ${colors.red};
    &:hover {
      color: ${colors.redHover};
    }
    &:hover[disabled] {
      color: ${colors.red};
    }
  `,
};

const colorOutlinedCssMap: Record<ColorsButtonVariant, CssSnippet> = {
  yellow: css`
    border: 1px solid ${colors.yellow};
    &:hover {
      color: ${colors.yellow};
    }
    &:hover[disabled] {
      color: ${colors.black};
    }
  `,
  green: css`
    border: 1px solid ${colors.green};

    &:hover {
      color: ${colors.green};
    }
    &:hover[disabled] {
      color: ${colors.black};
    }
  `,
  blue: css`
    border: 1px solid ${colors.blue};

    &:hover {
      color: ${colors.blue};
    }
    &:hover[disabled] {
      color: ${colors.black};
    }
  `,
  red: css`
    border: 1px solid ${colors.red};

    &:hover {
      color: ${colors.red};
    }
    &:hover[disabled] {
      color: ${colors.black};
    }
  `,
};

const colorContainedCssMap: Record<ColorsButtonVariant, CssSnippet> = {
  yellow: css`
    border-color: ${colors.yellow};
    background-color: ${colors.yellow};
    color: ${colors.black};
    &:hover {
      background-color: ${colors.yellowDark};
    }
    &:hover[disabled] {
      background-color: ${colors.yellow};
    }
  `,
  green: css`
    border-color: ${colors.green};
    background-color: ${colors.green};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.greenHover};
    }
    &:hover[disabled] {
      background-color: ${colors.green};
    }
  `,
  blue: css`
    border-color: ${colors.blue};
    background-color: ${colors.blue};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.blueButtonHover};
    }
    &:hover[disabled] {
      background-color: ${colors.blue};
    }
  `,
  red: css`
    border-color: ${colors.red};
    background-color: ${colors.red};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.redHover};
    }
    &:hover[disabled] {
      background-color: ${colors.red};
    }
  `,
};

export const IconComponent = styled.svg<{ position: 'left' | 'right' }>`
  margin: auto 13px auto 0;
  vertical-align: top;
  ${media.mobileSmall(css`
    margin-right: 10px;
  `)}

  ${({ position }) =>
    position === 'right' &&
    css`
      margin: auto 0 auto 13px;
      vertical-align: top;
      ${media.mobileSmall(css`
        margin-left: 10px;
      `)}
    `}
`;

export const StyledButton = styled.button<{
  colorVariant?: ColorVariant;
  modeVariant?: ModeButtonVariant;
  loader?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1;
  padding: 22px 30px;
  color: ${colors.black};
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  ${(props) =>
    props.loader &&
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        height: 20px;
        width: 20px;
        border-width: 3px;
        border-style: solid;
        border-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.75)
          rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25);
        border-radius: 100%;
        animation: ${spin} 0.5s linear infinite;
      }
    `};

  ${(props) =>
    props.modeVariant === 'text'
      ? css`
          padding: 10px 0;
          font-weight: 500;
          line-height: 100%;
          border-radius: 0;

          ${media.tabletSmall(css`
            font-size: 14px;
          `)}
        `
      : null}

  ${(props) => {
    switch (props.modeVariant) {
      case 'text':
        return typeof props.colorVariant === 'string'
          ? colorTextCssMap[props.colorVariant]
          : props.colorVariant;
      case 'outlined':
        return typeof props.colorVariant === 'string'
          ? colorOutlinedCssMap[props.colorVariant]
          : props.colorVariant;
      case 'contained':
        return typeof props.colorVariant === 'string'
          ? colorContainedCssMap[props.colorVariant]
          : props.colorVariant;
      default:
        return typeof props.colorVariant === 'string'
          ? colorTextCssMap[props.colorVariant]
          : props.colorVariant;
    }
  }};

  &:disabled {
    cursor: default;
    opacity: 70%;
  }
`;

const spin = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0)
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg)
  }
`;
