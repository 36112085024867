import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import OrderErrorMessage from '@/modules/Order/containers/OrderErrorMessage';
import { media } from '@/utils/mixin';

type Props = {
  children: React.ReactElement;
};

const OrderWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Component>
      <OrderErrorMessage />
      <ContentContainer>{children}</ContentContainer>
    </Component>
  );
};

const Component = styled.div`
  position: relative;
  padding: 70px 0;
  margin-top: -42px;
  height: 100%;

  ${media.mobile(css`
    min-height: calc(var(--vh, 1vh) * 100);
  `)}
`;

export default OrderWrapper;
