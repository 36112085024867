import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

type Props = {
  message?: string | null;
  type?: 'success' | 'error';
  position?: 'top' | 'bottom';
};

function Toast({ message, type, position = 'top' }: Props) {
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    setOpened(!!message);
  }, [message]);

  return (
    <ToastBlock
      isOpened={opened}
      isError={type === 'error'}
      position={position}
    >
      <ToastText>{message}</ToastText>
    </ToastBlock>
  );
}

export default Toast;

const ToastBlock = styled.div<{
  isOpened: boolean;
  isError: boolean;
  position: 'top' | 'bottom';
}>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  background-color: ${(props) => (props.isError ? ' #e74c3c' : ' #64ce83')};
  text-align: center;
  color: #ffffff;
  padding: 15px 25px;
  z-index: 10000000;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.25),
    0 0 1px 0 rgba(0, 0, 0, 0.25);
  transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  border-radius: 4px;
  margin-bottom: 2px;
  max-height: 400px;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;

  ${(props) =>
    props.position === 'top'
      ? css`top:-10px;translateX(-50%) translateY(-100%);`
      : css`bottom:-10px;translateX(-50%) translateY(100%);`};

  ${(props) =>
    props.position === 'top' && props.isOpened
      ? css`
          top: 30px;
        `
      : null};

  ${(props) =>
    props.position === 'top' && props.isOpened
      ? media.mobile(css`
          top: 10px;
        `)
      : null}

  ${(props) =>
    props.position === 'bottom' && props.isOpened
      ? css`
          bottom: 30px;
        `
      : null};

  ${(props) =>
    props.isOpened
      ? css`
          transform: translateX(-50%) translateY(0%);
          opacity: 1;
          visibility: visible;
        `
      : null}

  ${media.tabletSmall(css`
    max-width: 80%;
    font-size: 14px;
    padding: 10px 20px;
  `)}
`;

const ToastText = styled.span`<any()>`;
