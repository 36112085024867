import React, { FormEventHandler, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import OrderFormSidebarBlock from '@/modules/Order/containers/OrderFormSidebar/components/OrderFormSidebarBlock';
import TextInput from '@/components/TextInput';
import Button from '@/components/Button';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  promoActions,
  selectPromoSubmitLoading,
} from '@/modules/Order/store/promo';
import { applyPromoCode } from '@/services/requests/orders';
import { colors } from '@/constants/theme';
import { PromoCodeType } from '@/enums/promo-code-type';
import { PromoCodeDiscountType } from '@/enums/promo-code-discount-type';
import {
  selectOrderSubmitLoading,
  setPromoFormFocused,
} from '@/modules/Order/store/first-step';
import { isInViewport } from '@/modules/Order/utils';
import { scrollToElement } from '@/utils/scroll';
import { setErrorMessage } from '@/modules/Order/store/common';

type Props = {
  containerId?: string;
  visible: boolean;
};

const OrderFormPromoForm: React.FC<Props> = ({ visible, containerId }) => {
  const dispatch = useTypedDispatch();

  const formRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>('');

  const submitLoading = useTypedSelector(selectOrderSubmitLoading);
  const loading = useTypedSelector(selectPromoSubmitLoading);

  useEffect(() => {
    if (!visible) {
      setValue('');
      dispatch(setErrorMessage(null));
    } else {
      inputRef.current?.focus();
    }
  }, [visible, inputRef]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.style.display = visible ? 'block' : 'none';
    }

    if (visible && window.innerWidth < 768) {
      if (formRef.current && formRef.current.getBoundingClientRect().top > 0) {
        scrollToElement(formRef.current);
      }
    }
  }, [formRef, visible]);

  const scrollHandler = () => {
    if (formRef.current && !isInViewport(formRef.current)) {
      inputRef.current?.blur();
      window.removeEventListener('scroll', scrollHandler);
    }
  };

  const onInputBlur = () => {
    dispatch(setPromoFormFocused(false));

    window.removeEventListener('scroll', scrollHandler);
  };
  const onInputFocus = () => {
    dispatch(setPromoFormFocused(true));
    window.addEventListener('scroll', scrollHandler);
  };

  const onClose = () => {
    dispatch(promoActions.closeForm());
  };

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    dispatch(setErrorMessage(null));
    dispatch(promoActions.setSubmitLoading(true));

    try {
      const response = await applyPromoCode(value);
      dispatch(
        promoActions.setPromoValue({
          code: response.data.code,
          discount: response.data.discount,
          type:
            response.data.type === 'REFERRAL_CODE'
              ? PromoCodeType.Referral
              : PromoCodeType.Promo,
          discountType:
            response.data.discountType === 'DISCOUNT_MONEY'
              ? PromoCodeDiscountType.Amount
              : PromoCodeDiscountType.Percent,
        })
      );
    } catch (e: any) {
      dispatch(
        setErrorMessage(
          e.body?.errors?.code?.message ?? 'Ошибка применения промокода'
        )
      );
    } finally {
      dispatch(promoActions.setSubmitLoading(false));
    }
  };

  return (
    <Component ref={formRef} id={containerId}>
      <form onSubmit={onSubmit}>
        <Title>Введите промокод</Title>
        <TextInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={loading}
          ref={inputRef}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        <SubmitButton
          colorVariant="green"
          modeVariant="contained"
          loader={loading}
          disabled={value.length === 0 || submitLoading}
          type="submit"
        >
          Применить
        </SubmitButton>

        <CancelRow>
          <Button
            colorVariant="red"
            modeVariant="text"
            onClick={onClose}
            disabled={loading || submitLoading}
          >
            Отменить
          </Button>
        </CancelRow>
      </form>
    </Component>
  );
};

const Component = styled(OrderFormSidebarBlock)``;

const Title = styled.span`
  display: block;
  text-align: center;
  color: rgb(134, 143, 155);
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
`;

const SubmitButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

const Error = styled.span`
  padding: 10px 5px;
  text-align: center;
  color: ${colors.red};
  font-weight: bold;
  border: 1px solid ${colors.red};
  display: block;
  margin-top: 10px;
`;

const CancelRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0 -15px;

  button {
    font-size: 90%;
  }
`;

export default OrderFormPromoForm;
