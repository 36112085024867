import { StylesConfig } from 'react-select';

import { colors } from '@/constants/theme';
import { SelectOption } from '@/components/Select/Select.types';

export const selectStyles: StylesConfig<SelectOption, true> = {
  control: (styles, { isFocused, menuIsOpen }) => ({
    ...styles,
    height: '50px',
    border: `1px solid ${menuIsOpen ? colors.green : colors.border}`,
    boxShadow: 'none',
    ':hover': {
      cursor: 'pointer',
      border: `1px solid ${menuIsOpen ? colors.green : colors.border}`,
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: '14px',
    fontWeight: 500,
    padding: '0 10px',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '14px',
    fontWeight: 500,
    padding: '0 10px',
  }),
  option: (styles, { isSelected }) => ({
    padding: '10px 20px',
    fontSize: '16px',
    overflow: 'hidden',
    cursor: 'pointer',
    backgroundColor: isSelected ? '#f2f9fc' : '#fff',
    color: '#333',
    ':hover': {
      backgroundColor: '#f2f9fc',
    },
  }),
};
