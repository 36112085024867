import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

import { SelectProps } from '../Select.types';

const SelectMobile: React.FC<SelectProps> = ({ options, value, onChange }) => {
  return (
    <Select onChange={(e) => onChange && onChange(+e.target.value)}>
      {options.map((option) => (
        <option value={option.value} selected={option.value === value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

const Select = styled.select`
  display: block;

  width: 100%;
  height: 50px;
  border: 1px solid ${colors.border};
  line-height: 1.6;
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
  border-radius: 8px;
  color: ${colors.black};
  background: ${colors.white};
  appearance: none;

  &::placeholder {
    color: #b3b3bb;
  }

  &:focus {
    border-color: ${colors.green};
  }

  &:disabled {
    opacity: 0.9;
  }
`;

export default SelectMobile;
