import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';
import { OrderSettingsSubscription } from '@/services/requests/orders';

type CommonState = {
  selectedSubscription: OrderSettingsSubscription | null;
  submitLoading: boolean;
};

const initialState: CommonState = {
  selectedSubscription: null,
  submitLoading: false,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedSubscription(
      state,
      action: PayloadAction<OrderSettingsSubscription | null>
    ) {
      state.selectedSubscription = action.payload;
    },
    setSubmitLoading(state, action: PayloadAction<boolean>) {
      state.submitLoading = action.payload;
    },
  },
});

export function selectSelectedSubscription(
  state: AppState
): OrderSettingsSubscription | null {
  return state.orderForm.subscription.selectedSubscription;
}

export function selectSubscriptionSubmitLoading(state: AppState): boolean {
  return state.orderForm.subscription.submitLoading;
}

const actions = subscriptionSlice.actions;
export const setSelectedSubscription = actions.setSelectedSubscription;
export const resetSubscription = actions.reset;
export const setSubscriptionSubmitLoading = actions.setSubmitLoading;

export default subscriptionSlice.reducer;
