import React from 'react';
import styled from 'styled-components';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectIsOrderEdit, selectStep } from '@/modules/Order/store/common';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';
import {
  promoActions,
  selectPromoFormOpened,
  selectPromoValue,
} from '@/modules/Order/store/promo';
import { ReactComponent as PromocodeSvg } from '@/assets/svg/promocode.svg';

type Props = {
  isMobile: boolean;
};

const OrderFormPromoApply: React.FC<Props> = (props) => {
  const step = useTypedSelector(selectStep);
  const isOrderEdit = useTypedSelector(selectIsOrderEdit);

  const dispatch = useTypedDispatch();
  const promoFormOpened = useTypedSelector(selectPromoFormOpened);
  const promoValue = useTypedSelector(selectPromoValue);

  const submitLoading = useTypedSelector(selectOrderSubmitLoading);

  const onPromoOpen = () => {
    if (props.isMobile) {
      const containerElement = document.getElementById('promo-form-mobile');
      if (containerElement) {
        containerElement.style.display = 'block';
        const inputElement = containerElement.querySelector('input');
        if (inputElement) {
          inputElement.focus();
        }
      }
    }

    dispatch(promoActions.openForm());
  };

  return step !== 1 || promoFormOpened || promoValue ? null : (
    <PromoActionWrapper {...props}>
      <PromoAction onClick={onPromoOpen} disabled={submitLoading}>
        <PromocodeSvg />
        Применить промокод
      </PromoAction>
    </PromoActionWrapper>
  );
};

const PromoActionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PromoAction = styled.button`
  color: rgb(18, 207, 124);
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: 0.6s all ease;
  font-size: 18px;

  &[disabled] {
    opacity: 0.75;
    pointer-events: none;
  }

  svg {
    margin-right: 10px;
    align-items: center;
    transform: translateY(-1px);
  }

  &:hover {
    color: #10ba70;
  }
`;

export default OrderFormPromoApply;
