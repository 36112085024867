import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';

type PersonalState = {
  name: string;
  email: string;
};

const initialState: PersonalState = {
  name: '',
  email: '',
};

const personalSlice = createSlice({
  name: 'personal',
  initialState,
  reducers: {
    reset: () => initialState,
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setPersonalData(
      state,
      action: PayloadAction<{ name: string; email: string }>
    ) {
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
  },
});

export const personalActions = personalSlice.actions;

export const selectOrderEmail = (state: AppState) =>
  state.orderForm.personal.email;
export const selectOrderName = (state: AppState) =>
  state.orderForm.personal.name;

export default personalSlice.reducer;
