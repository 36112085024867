import React, { useEffect, useState } from 'react';

import { cookie } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { OrderFormMode } from '@/enums/order-form-mode';
import {
  resetOrderCommonState,
  selectInitialDataLoaded,
  selectOrderSettingsLoaded,
  selectOrderUserProfile,
  selectOrderUserProfileLoaded,
  selectStep,
  setMode,
  setStep,
} from '@/modules/Order/store/common';
import OrderCreateLoader from '@/modules/Order/modules/OrderCreate/containers/OrderCreateLoader';
import OrderCreateMain from '@/modules/Order/modules/OrderCreate/containers/OrderCreateMain';
import {
  resetPrimaryServices,
  selectServiceOptionsLoaded,
  setFirstServiceQuantity,
  setSecondServiceQuantity,
} from '@/modules/Order/store/services';
import {
  dateTimeActions,
  selectBusyDatesLoaded,
  selectCoefficientsLoaded,
} from '@/modules/Order/store/datetime';
import { applyReferralCode } from '@/services/requests/orders';
import { promoActions } from '@/modules/Order/store/promo';
import { PromoCodeType } from '@/enums/promo-code-type';
import { PromoCodeDiscountType } from '@/enums/promo-code-discount-type';
import { resetStepOne } from '@/modules/Order/store/first-step';
import { resetSecondaryServices } from '@/modules/Order/store/secondary-services';
import OrderWrapper from '@/modules/Order/containers/OrderWrapper';
import Spinner from '@/components/Spinner';
import { scrollToTop } from '@/utils/scroll';
import { addressActions } from '@/modules/Order/store/address';

type Props = {
  mode: OrderFormMode;
  referralCode: string | null;
  initState?: {
    rooms?: number;
    bathrooms?: number;
    windows?: number;
    balconies?: number;
  };
};

const OrderCreate: React.FC<Props> = ({ initState, referralCode, mode }) => {
  const dispatch = useTypedDispatch();

  const step = useTypedSelector(selectStep);

  const [promoLoading, setPromoLoading] = useState<boolean>(true);

  const loaded = useTypedSelector((state) => {
    return (
      selectOrderSettingsLoaded(state) &&
      selectServiceOptionsLoaded(state) &&
      selectBusyDatesLoaded(state) &&
      selectCoefficientsLoaded(state) &&
      selectInitialDataLoaded(state) &&
      selectOrderUserProfileLoaded(state)
    );
  });

  const userProfile = useTypedSelector(selectOrderUserProfile);

  useEffect(() => {
    dispatch(resetOrderCommonState());
    dispatch(setMode(mode));
    dispatch(setStep(1));
    dispatch(resetStepOne());
    dispatch(resetPrimaryServices());
    dispatch(resetSecondaryServices());

    dispatch(addressActions.reset());
    dispatch(dateTimeActions.reset());
    dispatch(promoActions.reset());
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [step]);

  useEffect(() => {
    if (!loaded) return;

    if (mode === OrderFormMode.Rooms && initState?.rooms) {
      dispatch(setFirstServiceQuantity(initState?.rooms));
      dispatch(setSecondServiceQuantity(initState?.bathrooms ?? 1));
    }

    if (mode === OrderFormMode.Windows && initState?.windows) {
      dispatch(setFirstServiceQuantity(initState?.windows));
      dispatch(setSecondServiceQuantity(initState?.balconies ?? 1));
    }
  }, [loaded]);

  useEffect(() => {
    if (!loaded) return;

    if (!referralCode) {
      setPromoLoading(false);
      return;
    }

    if (userProfile?.haveOrders) {
      setPromoLoading(false);
      cookie.remove('referral_code');
      return;
    }

    setPromoLoading(true);

    applyReferralCode(referralCode)
      .then((response) => {
        dispatch(
          promoActions.setPromoValue({
            code: response.data.code,
            discount: response.data.discount,
            type: PromoCodeType.Referral,
            discountType: PromoCodeDiscountType.Amount,
          })
        );
      })
      .catch((e) => {})
      .finally(() => setPromoLoading(false));
  }, [loaded]);

  if (mode === null) {
    return null;
  }

  return (
    <OrderWrapper>
      {!loaded ? (
        <OrderCreateLoader />
      ) : promoLoading ? (
        <Spinner />
      ) : (
        <OrderCreateMain />
      )}
    </OrderWrapper>
  );
};

export default OrderCreate;
