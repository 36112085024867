import React from 'react';
import styled, { css } from 'styled-components';

import { UserAddress } from '@/services/requests/orders';
import { colors } from '@/constants/theme';

type Props = {
  model: UserAddress | null;
  selected: boolean;
  onSelect: () => void;
};

const OrderFormUserAddress = ({ model, selected, onSelect }: Props) => {
  return (
    <Component $selected={selected} onClick={() => !selected && onSelect()}>
      <Left>
        <Toggle>
          <ToggleInner />
        </Toggle>
      </Left>

      <Right>
        {model ? (
          <Type>{model.type === 'APARTMENT' ? 'Квартира' : 'Частный дом'}</Type>
        ) : null}

        {model === null ? (
          <EmptyText>Новый адрес</EmptyText>
        ) : (
          <Text>{model.raw}</Text>
        )}
      </Right>
    </Component>
  );
};

const Left = styled.div``;

const Right = styled.div`
  flex: 1 1 1px;
`;

const Toggle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid ${colors.grayDark};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease;
`;

const ToggleInner = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${colors.green};
  opacity: 0;
  transition: 0.3s all ease;
`;

const Text = styled.span`
  display: block;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.8;
  transition: 0.3s all ease;
`;

const EmptyText = styled.span`
  font-size: 14px;
  line-height: 30px;
  font-weight: bold;
`;

const Type = styled.span`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
  display: block;
`;

const Component = styled.div<{ $selected: boolean }>`
  border-radius: 5px;
  border: 2px solid ${colors.border};
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.3s all ease;

  &:hover {
    border-color: ${colors.grayDark};
  }

  ${(props) =>
    props.$selected &&
    css`
      border-color: ${colors.green} !important;
      ${Toggle} {
        border-color: ${colors.green};
      }
      ${ToggleInner} {
        opacity: 1;
      }
      ${Text} {
        opacity: 1;
      }
    `}
`;

export default OrderFormUserAddress;
