import React, { useEffect } from 'react';
import * as Sentry from '@sentry/node';
import Head from 'next/head';
import App from 'next/app';

import 'react-datepicker/dist/react-datepicker.css';

import { AdminBar } from '@tager/web-panel';
import { useAnalytics } from '@tager/web-analytics';
// eslint-disable-next-line import/order
import {
  ACCESS_TOKEN_COOKIE,
  api,
  cookie,
  isServer,
  REFRESH_TOKEN_COOKIE,
  useFixedVhProperty,
  useProgressBar,
} from '@tager/web-core';

import '@/assets/css/index.css';

import { ModalProvider } from '@tager/web-components';

import withRedux from '@/hocs/withRedux';
import { CustomApp_Component } from '@/typings/hocs';
import { checkAuthorizationThunk } from '@/store/reducers/auth';
import { i18n, appWithTranslation } from '@/i18n';
import { geolocation } from '@/services/requests/misc';
import { citiesActions } from '@/store/reducers/cities';

Sentry.init({
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.NEXT_PUBLIC_ENV !== 'local',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: [
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    process.env.NEXT_PUBLIC_ENV,
  ].join('_'),
});

function getQueryParam(name: string): string {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * Custom App documentation
 * https://nextjs.org/docs/advanced-features/custom-app
 */
const CustomApp: CustomApp_Component = (props) => {
  useProgressBar({ showSpinner: false });
  useAnalytics({ useBackend: false });
  useFixedVhProperty({ shouldListenResize: true });

  useEffect(() => {
    i18n.on('languageChanged', (lang: string) => cookie.set('lng', lang));
  }, []);

  useEffect(() => {
    if (getQueryParam('utm_source')) {
      localStorage.setItem('utmSource', getQueryParam('utm_source'));
    }
    if (getQueryParam('utm_campaign')) {
      localStorage.setItem('utmCampaign', getQueryParam('utm_campaign'));
    }
  }, []);

  const { Component, pageProps } = props;

  // Workaround for https://github.com/zeit/next.js/issues/8592
  // @ts-ignore
  const { err } = props;
  const modifiedPageProps = { ...pageProps, err };
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <AdminBar />
      <ModalProvider>
        <Component {...modifiedPageProps} />
      </ModalProvider>
    </>
  );
};

/**
 * Only use this method if you have blocking data requirements for
 * every single page in your application. This disables the ability to
 * perform automatic static optimization, causing every page in your app to
 * be server-side rendered.
 *
 * Reference: https://nextjs.org/docs/advanced-features/custom-app
 */
CustomApp.getInitialProps = async (appContext) => {
  const { ctx } = appContext;

  const cookieCityId = cookie.get('cityId', ctx.req);
  if (!cookieCityId) {
    const forwarded = ctx.req?.headers['x-forwarded-for'];
    const ipAddress =
      typeof forwarded === 'string'
        ? forwarded.split(/, /)[0]
        : ctx.req?.socket.remoteAddress;

    if (ipAddress) {
      try {
        const userCity = await geolocation(ipAddress);
        cookie.set('cityId', String(userCity.id), ctx.res);
      } catch (e) {}
    }
  }

  if (isServer() && ctx.req) {
    const accessToken = cookie.get(ACCESS_TOKEN_COOKIE, ctx.req);
    const refreshToken = cookie.get(REFRESH_TOKEN_COOKIE, ctx.req);

    api.setAccessToken(accessToken);
    api.setRefreshToken(refreshToken);
    api.setConfig({ useRefreshToken: true });

    await ctx.store.dispatch(checkAuthorizationThunk());
  }

  /** calls page's `getInitialProps` and fills `appProps.pageProps` */
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default appWithTranslation(withRedux(CustomApp));
