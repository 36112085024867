import React from 'react';
import styled, { css } from 'styled-components';

import { Option } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  handleCount: (count: number) => void;
  count: number;
  options: Array<Option<number>>;
};

function Counter({ handleCount, count, options }: Props) {
  return (
    <Inner>
      <DecrementButton type="button" onClick={() => handleCount(count - 1)}>
        -
      </DecrementButton>
      <LabelText>{options[count]?.label}</LabelText>
      <IncrementButton
        type="button"
        onClick={() => handleCount(count + 1)}
        disabled={count === options.length - 1}
      >
        +
      </IncrementButton>
    </Inner>
  );
}

export default Counter;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;
const LabelText = styled.span`
  display: block;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  font-weight: 500;
  background-color: ${colors.white};
  flex: 1 1 1px;
  white-space: nowrap;
  padding: 0 2px;
`;

const CounterButton = styled.button`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: 2px solid ${colors.border};
  background-color: ${colors.white};
  overflow: hidden;
  cursor: pointer;
  color: ${colors.darkText};

  &:hover {
    background-color: #e2e2e2;
    border: 2px solid #e2e2e2;
  }

  ${media.mobileLarge(css`
    height: 35px;
  `)}

  :disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const DecrementButton = styled(CounterButton)`
  background-color: #efefef;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 4px 0 0 4px;
`;

const IncrementButton = styled(CounterButton)`
  background-color: #efefef;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
`;
