export function generateTimeOptions(): string[] {
  const result = [];

  for (let h = 8; h <= 20; h++) {
    for (let m = 0; m <= (h === 20 ? 0 : 30); m += 30) {
      const label = (h < 10 ? '0' : '') + h + ':' + (m < 30 ? '0' : '') + m;
      result.push(label);
    }
  }

  return result;
}

export function isInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
