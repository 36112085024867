import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

type TextInputProps = {
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextInputProps>(
  ({ className, label, value, ...rest }: TextInputProps, ref) => {
    return (
      <Component className={className}>
        {label ? <Label>{label}</Label> : null}
        <Input {...rest} ref={ref} value={value || ''} />
      </Component>
    );
  }
);

export default TextArea;

const Component = styled.div`
  position: relative;
`;

const Input = styled.textarea<{
  error?: string;
}>`
  width: 100%;
  height: 82px;
  border: 1px solid ${colors.border};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 10px;
  border-radius: 8px;
  color: ${colors.black};
  resize: none;

  &::placeholder {
    color: #b3b3bb;
  }

  &:focus {
    border-color: ${colors.green};
  }

  &:disabled {
    opacity: 0.9;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.red};
    `}
`;

const Label = styled.label`
  font-size: 13px;
  color: #3d3d3d;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  white-space: nowrap;
`;
