import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PrimaryOrderServiceType } from '@/typings/model';
import { AppState } from '@/store/store';
import { getPrimaryOrderServices } from '@/services/requests/requests';

type PricesState = {
  roomAndBathroomInfoList: { data: Array<PrimaryOrderServiceType> };
  windowAndBalconyInfoList: { data: Array<PrimaryOrderServiceType> };
};

export const getRoomAndBathroomInfoListThunk = createAsyncThunk<{
  data: Array<PrimaryOrderServiceType>;
}>('prices/getRoomPriceList', async (arg, thunkAPI) => {
  try {
    return await getPrimaryOrderServices({ names: 'ROOMS,BATHROOMS' });
  } catch (error) {
    return { data: [] };
  }
});

export const getWindowAndBalconyInfoListThunk = createAsyncThunk<{
  data: Array<PrimaryOrderServiceType>;
}>('prices/getWindowAndBalconyInfoList', async (arg, thunkAPI) => {
  try {
    return await getPrimaryOrderServices({ names: 'WINDOWS,BALCONIES' });
  } catch (error) {
    return { data: [] };
  }
});

const initialState: PricesState = {
  roomAndBathroomInfoList: { data: [] },
  windowAndBalconyInfoList: { data: [] },
};

const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoomAndBathroomInfoListThunk.fulfilled, (state, action) => {
        state.roomAndBathroomInfoList = action.payload;
      })
      .addCase(getWindowAndBalconyInfoListThunk.fulfilled, (state, action) => {
        state.windowAndBalconyInfoList = action.payload;
      });
  },
});

export function roomAndBathroomInfoListSelector(
  state: AppState
): Array<PrimaryOrderServiceType> {
  return state.prices.roomAndBathroomInfoList.data;
}

export function windowAndBalconyInfoListSelector(
  state: AppState
): Array<PrimaryOrderServiceType> {
  return state.prices.windowAndBalconyInfoList.data;
}

export const pricesActions = pricesSlice.actions;

export default pricesSlice.reducer;
