import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  className?: string;
  error?: string;
  children: React.ReactNode;
};

function FormControl({ className, error, children }: Props) {
  return (
    <InputWrapper className={className}>
      {children}
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  position: relative;
`;

const ErrorMessage = styled.span`
  position: absolute;
  top: 100%;
  margin-top: 2px;
  font-size: 12px;
  white-space: unset;
  color: ${colors.red};

  width: 100%;
  right: 0;
  text-align: left;
  font-weight: 700;
`;

export default FormControl;
