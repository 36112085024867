import React from 'react';

import { isStringGuard, Nullable, RequestError } from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import { Address, ThemeColor } from '@/typings/common';
import { colors } from '@/constants/theme';
import { ErrorCode, PrimaryOrderServiceType } from '@/typings/model';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function getParamAsString(
  param: Array<string> | string | undefined
): string {
  return Array.isArray(param) ? param[0] : param ?? '';
}

export function getPhoneNumber(formattedNumber: string): string {
  return formattedNumber
    .split('')
    .filter((character) => /[\d+]/.test(character))
    .join('');
}

export function getColorByVariant(variant: ThemeColor): string {
  switch (variant) {
    case 'blue':
      return colors.blue;
    case 'green':
      return colors.green;
    case 'yellow':
      return colors.yellow;
  }
}

export function getErrorLabel(values: ErrorCode | string) {
  switch (values) {
    case 'User Not Found':
      return 'Такого пользователя не существует';
    case 'Invalid Password':
      return 'Пароль введён неверно';
    case 'VALIDATION_MIN_STRING_LENGTH':
      return 'Минимум 4 символа ';
    // case 'CaseDiff':
    //   return 'Пароль должен содержать буквы верхнего и нижнего регистров';
    // case 'Symbols':
    //   return 'Пароль должен содержать символы';
    // case 'Numbers':
    //   return 'Пароль должен содержать цифры';

    default:
      return 'Произошла ошибка';
  }
}

export function getWebsiteOrigin(): string {
  return typeof window !== 'undefined' ? window.location.origin : '/';
}

export function getPrimaryOrderServiceBySystemName(
  primaryOrderServiceList: Array<PrimaryOrderServiceType>,
  systemName: string
): Nullable<PrimaryOrderServiceType> {
  return (
    primaryOrderServiceList.find(
      (primaryOrderService) => primaryOrderService.systemName === systemName
    ) ?? null
  );
}

export function numberTo2(number: number | string): string {
  return (number < 10 ? '0' + number : number).toString();
}

export function dateToString(date: Date): string {
  return [
    date.getFullYear(),
    numberTo2(date.getMonth() + 1),
    numberTo2(date.getDate()),
  ].join('-');
}

export function stringToDate(date: string): Date {
  return new Date(Date.parse(date));
}

export function dateToHumanString(date: Date): string {
  return (
    numberTo2(date.getDate()) +
    '.' +
    numberTo2(date.getMonth() + 1) +
    '.' +
    date.getFullYear()
  );
}

export function hoursHumanString(hours: number): string {
  if (hours % 10 == 1 && hours % 100 !== 11) {
    return hours + ' час';
  }
  if (
    (hours % 10 === 2 || hours % 10 === 3 || hours % 10 === 4) &&
    hours % 100 !== 12 &&
    hours % 100 !== 13 &&
    hours % 100 !== 14
  ) {
    return hours + ' часа';
  }
  return hours + ' часов';
}

export function workTimeHumanString(minutes: number): string {
  if (minutes < 60) {
    return minutes + ' мин.';
  }

  let hours = minutes / 60;
  hours = Math.round(hours * 100) / 100;

  return hours + ' ч.';
}

export function getAddressHumanString(address: Address): string {
  let result = `г. ${address.city}, `;
  if (address.street) {
    result += address.street;
  }

  if (address.house) {
    result +=
      ' д. ' +
      address.house +
      (address.frame ? ' корпус ' + address.frame : '');
  }

  if (address.entrance) {
    result +=
      ', подъезд ' +
      address.entrance +
      (address.intercomCode ? ' (код - ' + address.intercomCode + ')' : '');
  }

  if (address.flat) {
    result += ', кв. ' + address.flat;
  }

  if (address.floor) {
    result += ' (этаж ' + address.floor + ')';
  }

  return result;
}
