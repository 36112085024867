import React, { useRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled, { css } from 'styled-components';
import { ru } from 'date-fns/locale';

import { Nullable } from '@tager/web-core';

import CustomDay from '@/components/Calendar/components/CustomDay';
import { colors } from '@/constants/theme';
import CustomInput from '@/components/Calendar/components/CustomInput';
import CustomHeader from '@/components/Calendar/components/CustomHeader';
import { media } from '@/utils/mixin';
import { scrollToElement } from '@/utils/scroll';

export type CalendarProps = ReactDatePickerProps & {
  id?: string;
  value: Nullable<Date>;
  disabledDates?: Array<string | undefined>;
  dayCoefficients?: Array<{ date: string; coefficient: number }>;
  isHideRaisingCoefficient?: boolean;
  minDate?: Date | null;
};

function Calendar({
  id,
  onChange,
  value,
  disabled,
  className,
  disabledDates = [],
  dayCoefficients = [],
  isHideRaisingCoefficient = false,
  minDate = null,
  locale,
  ...rest
}: CalendarProps) {
  const ref = useRef<HTMLDivElement>(null);

  const onCalendarOpen = () => {
    if (window.innerWidth < 767 && ref.current) {
      scrollToElement(ref.current);
    }
  };

  return (
    <Wrapper id={id} ref={ref}>
      <ReactDatePicker
        selected={value}
        locale={locale}
        dateFormat="dd.MM.yyyy"
        dropdownMode="select"
        minDate={minDate}
        onChange={onChange}
        onCalendarOpen={onCalendarOpen}
        calendarStartDay={1}
        showPopperArrow={false}
        popperPlacement={'top-start'}
        customInput={<CustomInput />}
        renderDayContents={(dayOfMonth, date) => (
          <CustomDay
            dayOfMonth={dayOfMonth}
            dayCoefficients={dayCoefficients}
            currentDay={value}
            disabledDates={disabledDates}
            date={date}
            isHideRaisingCoefficient={isHideRaisingCoefficient}
          />
        )}
        renderCustomHeader={(headerProps) => (
          <CustomHeader headerProps={headerProps} locale={ru} />
        )}
        calendarClassName="datePicker"
        wrapperClassName={'customWrapper'}
        dayClassName={(date) =>
          disabledDates?.some((disabledDate) =>
            disabledDate
              ? new Date(disabledDate).setHours(0) === date.setHours(0)
              : false
          )
            ? 'disabled-date'
            : null
        }
        disabledKeyboardNavigation
        popperClassName={'customPopper'}
        {...rest}
      />
    </Wrapper>
  );
}

export default Calendar;

const Wrapper = styled.div`
  position: relative;

  .react-datepicker-popper {
    width: 366px;
    top: 44px !important;
    left: 0 !important;
    transform: none !important;
    z-index: 1000;
    ${media.mobileMedium(css`
      width: auto;
    `)}
  }

  .customWrapper {
    display: block;
  }

  .customPopper {
    ${media.mobileMedium(css`
      left: 50% !important;
      transform: translateX(-50%) !important;
    `)}
    .datePicker {
      font-family: 'MuseoSans', -apple-system;
      border: 0 none;
      border-radius: 8px;
      box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
      padding: 20px 15px;
      background-color: #fff;
      margin-top: 10px;

      ${media.mobileMedium(css`
        padding: 15px 5px;
      `)}
      .react-datepicker__header {
        background: transparent;
        border: 0 none;
        padding-top: 0;
        border-radius: 0 !important;
        margin-bottom: 20px;

        .react-datepicker__day-name {
          color: #88919d;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          height: 18px;
          width: 48px;
          text-transform: capitalize;
          margin: 0;
          ${media.mobileMedium(css`
            width: 44px;
          `)}
        }
      }

      .react-datepicker__month {
        margin: 0;

        .react-datepicker__week {
          display: flex;

          &:not(:last-child) {
            border-bottom: 1px solid #f4f4f4;
          }

          .react-datepicker__day {
            width: 48px;
            height: 56px;
            margin: 0;
            font-size: 16px;
            border-radius: unset;
            ${media.mobileMedium(css`
              width: 44px;
              height: 52px;
            `)}
          }

          .disabled-date {
            pointer-events: none;
            color: #88919d;
          }

          .react-datepicker__day--selected {
            background-color: ${colors.green};
          }
        }
      }
    }
  }
`;
