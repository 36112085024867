import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '@tager/web-core';

import { PrimaryOrderServiceType, PrimaryServiceType } from '@/typings/model';
import { AppState } from '@/store/store';
import { getPrimaryOrderServices } from '@/services/requests/requests';

type ServiceValue = Nullable<{
  id: number | null;
  quantity: number;
}>;

type ServicesState = {
  firstServiceInitialQuantity: number | null;
  firstServiceValue: ServiceValue;
  firstServiceOptions: Array<PrimaryServiceType>;

  secondServiceInitialQuantity: number | null;
  secondServiceValue: ServiceValue;
  secondServiceOptions: Array<PrimaryServiceType>;

  serviceOptionsLoaded: boolean;
};

const initialState: ServicesState = {
  firstServiceInitialQuantity: null,
  firstServiceOptions: [],
  firstServiceValue: null,

  secondServiceInitialQuantity: null,
  secondServiceOptions: [],
  secondServiceValue: null,

  serviceOptionsLoaded: false,
};

export const loadRoomsAndBathroomsServicesThunk = createAsyncThunk<
  Array<PrimaryOrderServiceType>
>('services/loadRoomsAndBathroomsServices', async () => {
  try {
    const response = await getPrimaryOrderServices({
      names: 'ROOMS,BATHROOMS',
    });
    return response.data;
  } catch (error) {
    return [];
  }
});

export const loadWindowsAndBalconiesServicesThunk = createAsyncThunk<
  Array<PrimaryOrderServiceType>
>('services/loadWindowsAndBalconiesServices', async () => {
  try {
    const response = await getPrimaryOrderServices({
      names: 'WINDOWS,BALCONIES',
    });
    return response.data;
  } catch (error) {
    return [];
  }
});

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    reset: () => initialState,
    setFirstServiceQuantity(state, action: PayloadAction<number>) {
      if (!state.firstServiceValue) {
        state.firstServiceInitialQuantity = action.payload;
      }

      const found = state.firstServiceOptions.find(
        (item) => item.quantity === action.payload
      );

      if (found) {
        state.firstServiceValue = { id: found.id, quantity: found.quantity };
      }
    },
    setSecondServiceQuantity(state, action: PayloadAction<number>) {
      if (!state.secondServiceValue) {
        state.secondServiceInitialQuantity = action.payload;
      }

      const found = state.secondServiceOptions.find(
        (item) => item.quantity === action.payload
      );

      if (found) {
        state.secondServiceValue = { id: found.id, quantity: found.quantity };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadRoomsAndBathroomsServicesThunk.pending, (state, action) => {
        state.firstServiceOptions = state.secondServiceOptions = [];
        state.serviceOptionsLoaded = false;
      })
      .addCase(
        loadRoomsAndBathroomsServicesThunk.fulfilled,
        (state, action) => {
          state.firstServiceOptions = action.payload[0].services;

          if (!state.firstServiceInitialQuantity) {
            state.firstServiceValue = {
              id: action.payload[0].services[0].id,
              quantity: action.payload[0].services[0].quantity,
            };
          } else {
            const found = state.firstServiceOptions.find(
              (item) => item.quantity === state.firstServiceInitialQuantity
            );

            if (found) {
              state.firstServiceValue = {
                id: found.id,
                quantity: found.quantity,
              };
            }
          }

          state.secondServiceOptions = action.payload[1].services;
          if (!state.secondServiceInitialQuantity) {
            state.secondServiceValue = {
              id: action.payload[1].services[0].id,
              quantity: action.payload[1].services[0].quantity,
            };
          } else {
            const found = state.secondServiceOptions.find(
              (item) => item.quantity === state.secondServiceInitialQuantity
            );

            if (found) {
              state.secondServiceValue = {
                id: found.id,
                quantity: found.quantity,
              };
            }
          }
          state.serviceOptionsLoaded = true;
        }
      );

    builder
      .addCase(
        loadWindowsAndBalconiesServicesThunk.pending,
        (state, action) => {
          state.firstServiceOptions = state.secondServiceOptions = [];
          state.serviceOptionsLoaded = false;
        }
      )
      .addCase(
        loadWindowsAndBalconiesServicesThunk.fulfilled,
        (state, action) => {
          state.firstServiceOptions = action.payload[0].services;

          if (!state.firstServiceInitialQuantity) {
            state.firstServiceValue = {
              id: action.payload[0].services[0].id,
              quantity: action.payload[0].services[0].quantity,
            };
          } else {
            const found = state.firstServiceOptions.find(
              (item) => item.quantity === state.firstServiceInitialQuantity
            );

            if (found) {
              state.firstServiceValue = {
                id: found.id,
                quantity: found.quantity,
              };
            }
          }

          state.secondServiceOptions = action.payload[1].services;

          if (action.payload[1].emptyValueLabel) {
            state.secondServiceOptions = [
              {
                id: null,
                quantity: 0,
                name: action.payload[1].emptyValueLabel,
                price: 0,
                minutes: 0,
              },
              ...state.secondServiceOptions,
            ];
          }

          if (!state.secondServiceInitialQuantity) {
            state.secondServiceValue = {
              id: state.secondServiceOptions[0].id,
              quantity: state.secondServiceOptions[0].quantity,
            };
          } else {
            const found = state.secondServiceOptions.find(
              (item) => item.quantity === state.secondServiceInitialQuantity
            );

            if (found) {
              state.secondServiceValue = {
                id: found.id,
                quantity: found.quantity,
              };
            }
          }
          state.serviceOptionsLoaded = true;
        }
      );
  },
});

export function selectFirstServiceOptions(
  state: AppState
): Array<PrimaryServiceType> {
  return state.orderForm.services.firstServiceOptions;
}

export function selectSecondServiceOptions(
  state: AppState
): Array<PrimaryServiceType> {
  return state.orderForm.services.secondServiceOptions;
}

export function selectServiceOptionsLoaded(state: AppState): boolean {
  return state.orderForm.services.serviceOptionsLoaded;
}

export function selectFirstServiceValue(state: AppState): ServiceValue {
  return state.orderForm.services.firstServiceValue;
}

export function selectSecondServiceValue(state: AppState): ServiceValue {
  return state.orderForm.services.secondServiceValue;
}

export const setFirstServiceQuantity =
  servicesSlice.actions.setFirstServiceQuantity;
export const setSecondServiceQuantity =
  servicesSlice.actions.setSecondServiceQuantity;
export const resetPrimaryServices = servicesSlice.actions.reset;

export default servicesSlice.reducer;
