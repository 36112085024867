import { combineReducers } from 'redux';

import { orderStore } from '../../modules/Order';

import citiesReducer from './cities';
import authReducer from './auth';
import pricesReducer from './prices';
import seoPageReducer from './seoPage';
import seoPagesListReducer from './seoPagesList';
import orderReducer from './order';
import settingsReducer from './tager/settings';
import seoReducer from './tager/seo';
import pagesReducer from './tager/pages';
import menusReducer from './tager/menus';

const tagerReducer = combineReducers({
  menus: menusReducer,
  pages: pagesReducer,
  seo: seoReducer,
  settings: settingsReducer,
});

export default combineReducers({
  tager: tagerReducer,
  cities: citiesReducer,
  prices: pricesReducer,
  seoPage: seoPageReducer,
  seoPagesList: seoPagesListReducer,
  settings: settingsReducer,
  order: orderReducer,
  orderForm: orderStore,
  auth: authReducer,
});
